import React from "react";
import {Box, Tooltip, Text} from "@wix/design-system";
import {Link} from "@wix/wix-ui-icons-common";

interface LinkSubtitleProps {
    subtitle: string;
}

const LinkSubtitle: React.FC<LinkSubtitleProps> = ({ subtitle }) => {
    return (
        <Box direction="horizontal">
            <Tooltip inline content="Short Link">
                <Link color="rgb(56, 153, 237)"/>
            </Tooltip>
            <Text ellipsis>{subtitle}</Text>
        </Box>
    );
}

export default LinkSubtitle;
