import {RequiredOptions} from "qr-code-styling/lib/core/QROptions";
import {ExtensionOptions} from "qr-border-plugin";
import {QrCodeLogoType} from "../components/qrcode/QrCodeLogoSelector";

export interface ShortLinkRequest {
    longUrl: string;
    title?: string;
    customShortUrlCode?: string;
    domainId?: number;
}

export interface ShortLinkResponse {
    id: number;
    shortUrlCode: string;
    longUrl: string;
    title: string;
    clicks: number;
    qrCodeId?: number;
    domain: DomainResponse;
    createdDate: Date;
    ttl: number;
}

export interface ShortLinkUpdateRequest {
    id: number;
    title?: string;
    longUrl?: string;
}

export interface ShortLinkDeleteRequest {
    shortUrlCodes: string[]
}

export enum AggregationPeriod {
    Daily = 'Daily',
    Monthly = 'Monthly'
}

export enum ViewSource {
    Qr = 'QR',
    Browser = 'BROWSER'
}

export interface ViewAnalyticsRequest {
    linkId: number,
    startDate: string,
    endDate: string, // TODO change to iso date
    timeZone: string,
    aggregationPeriod: AggregationPeriod,
    viewSources: ViewSource[],
}

export interface ViewAnalyticsResponse {
    linkId: number,
    views: Map<string, number>,
    qrViews: Map<string, number>,
    countryViews: Map<string, ViewsPercentage>,
    cityViews: Map<string, ViewsPercentage>,
    deviceTypes: Map<string, ViewsPercentage>,
    operatingSystems: Map<string, ViewsPercentage>,
    browsers: Map<string, ViewsPercentage>
}

export interface ViewsPercentage {
    viewCount: number,
    percentage: number
}

export enum VendorProductId {
    NOT_PURCHASED = 'NOT_PURCHASED',
    basic = "basic",
    business = "business",
    ultimate = "ultimate"
}

export interface InstanceInfoResponse {
    appId: string,
    siteId: string,
    vendorProductId: VendorProductId,
    availableLinks: number,
    availableCustomShortUrlCodes: number,
    availableQrCodes: number,
    linksLimit: number,
    customShortUrlLimit: number,
    qrCodesLimit: number,
    isFreeTrialAvailable: boolean
}

export enum VerificationStatus {
    Verified = 'VERIFIED',
    InProgress = 'IN_PROGRESS',
    Failed = 'FAILED',
    Deleted = 'DELETED',
}

export interface DomainResponse {
    id: number,
    domainName: string,
    verificationStatus: VerificationStatus,
    createdDate: Date
}

export interface QrCodeRequest {
    qrCodeStyle: RequiredOptions,
    borderStyle?: ExtensionOptions,
    metaData: QrCodeMetaData,
}

export interface QrCodeResponse {
    id: number,
    qrCodeStyle: RequiredOptions,
    borderStyle?: ExtensionOptions,
    metaData: QrCodeMetaData,
}

export interface QrCodeMetaData {
    logoType: QrCodeLogoType,
    customLogoFileName?: string
}
