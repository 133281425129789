import {useMutation, useQueryClient} from "@tanstack/react-query";

import {createQrCodeCode} from "../api/qrCodeClient";

export const useGenerateQrCode = () => {
    const queryClient = useQueryClient();

    const { mutateAsync: generateQrCode } = useMutation({
        mutationFn: createQrCodeCode,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["instance"] });
            queryClient.invalidateQueries({ queryKey: ["shortLinks"] });
        }
    });

    return generateQrCode;
};
