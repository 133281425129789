import React, {ChangeEvent, useState,} from 'react';
import {useSearchParams} from "react-router-dom";
import {
    Box,
    CustomModalLayout,
    Dropdown,
    DropdownLayoutValueOption,
    FormField,
    Heading,
    Input,
    Modal,
    Text,
    ToggleSwitch,
    Tooltip,
} from '@wix/design-system';
import {StatusType} from '@wix/design-system/dist/types/FormField';
import {InfoCircleSmall, LockLocked} from "@wix/wix-ui-icons-common";
import {ValidationError} from 'yup';

import {
    ShortLinkRequest,
    VendorProductId,
    VerificationStatus
} from '../../models/models';
import {cutLinkSchema} from '../../validations/cutLinkValidation';
import UpgradeTooltipMessage from "../tooltip/UpgradeTooltipMessage";
import {useCutLink} from "../../store/shortLinkStore";
import {useInstance} from "../../store/instanceStore";
import {shouldDisableDomainManagement} from "../../helpers/upgradeBannerHelper";
import {useGenerateQrCode} from "../../store/qrCodeStore";
import {useDomains} from "../../store/domainStore";

interface CutLinkProps {
    onSave: (linkId: number, shortUrl: string, title: string, qrCodeId: number | undefined) => void
    onClose: () => void,
}

const CutLink: React.FC<CutLinkProps> = (
    {
        onSave,
        onClose
    }) => {

  const DEFAULT_DOMAIN_ID: number = -1;

  const [searchParams] = useSearchParams();

  const instance = useInstance(searchParams);
  const cutLink = useCutLink();
  const generateQrCode = useGenerateQrCode();
  const domains = useDomains(searchParams, new Set([VerificationStatus.Verified]));

  const [showModal, setShowModal] = useState(true);
  const [urlFieldStatus, setUrlFieldStatus] = useState<StatusType>();
  const [urlStatusMessage, setUrlStatusMessage] = useState("");
  const [titleFieldStatus, setTitleFieldStatus] = useState<StatusType>();
  const [titleStatusMessage, setTitleStatusMessage] = useState("");
  const [customShortUrlFieldStatus, setCustomShortUrlFieldStatus] = useState<StatusType>();
  const [customShortUrlFieldStatusMessage, setCustomShortUrlFieldStatusMessage] = useState("");
  const [selectedDomainOptionId, setSelectedDomainOptionId] = useState<number>(domains.length === 0 ? DEFAULT_DOMAIN_ID : domains[domains.length - 1].id);
  const [shortLink, setShortLink] = useState<ShortLinkRequest>(
      {
          longUrl: '',
          domainId: domains.length === 0 ? DEFAULT_DOMAIN_ID : domains[domains.length - 1].id
      });
  const [isQrCodeApplied, setIsQrCodeApplied] = useState<boolean>(false);

  const dropdownOptions = () => {
      const customDomainOptions = domains.map((domain) => ({
          id: domain.id,
          value: domain.domainName,
          label: domain.domainName
      }));

      customDomainOptions.push({
          id: DEFAULT_DOMAIN_ID,
          value: 'tnlnk.co',
          label: 'tnlnk.co'
      });

      return customDomainOptions;
  }

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>, inputName: string) => {
      const newValue = event.target.value;
      setShortLink(prevState => ({
          ...prevState,
          [inputName]: newValue.trim()
      }));
  }

  const handleCustomDomainChange = (valueOptions: DropdownLayoutValueOption) => {
      const domainId = Number(valueOptions.id);
      setSelectedDomainOptionId(domainId);

      setShortLink(prevState => ({...prevState, domainId: Number(domainId)}));
  }

  const handleToggleSwitchChange = (event: ChangeEvent<HTMLInputElement>) => {
      const generateQrCode = event.target.checked;
      setIsQrCodeApplied(generateQrCode);
  }

  const setInputFieldError = (field: string | undefined, message: string) => {
      if (field === 'url') {
          setUrlFieldStatus("error")
          setUrlStatusMessage(message)
      } else if (field === 'title') {
          setTitleFieldStatus("error")
          setTitleStatusMessage(message)
      } else if (field === 'customShortUrlCode') {
          setCustomShortUrlFieldStatus('error')
          setCustomShortUrlFieldStatusMessage(message)
      }
  }

  const handleSave = async () => {
      try {
          cutLinkSchema.validateSync({
              url: shortLink.longUrl,
              title: shortLink.title,
              customShortUrlCode: shortLink.customShortUrlCode,
          });
      } catch (error: any) {
          if (error instanceof ValidationError) {
              setInputFieldError(error.path, error.message);
          }
          return;
      }

      try {
          const { id: shortLinkId, title, shortUrlCode, domain } = await cutLink({shortLink, searchParams});
          const shortUrl = `https://${domain.domainName}/${shortUrlCode}`;

          const qrCodeId =
              isQrCodeApplied
                  ? (await generateQrCode({ shortLinkId, searchParams }))?.id
                  : undefined;

          onSave(shortLinkId, shortUrl, title, qrCodeId);
          handleClose();
      } catch (error: any) {
          if (error.data?.error === "DuplicateCustomShortUrlCodeException") {
              setInputFieldError('customShortUrlCode', error.data.message);
          }
      }
  };

  const handleClose = () => {
      setShowModal(false);
      onClose();
  };

  return (
      <Modal isOpen={showModal}
             onRequestClose={handleClose}
             shouldCloseOnOverlayClick
             screen="desktop">

          <CustomModalLayout primaryButtonText="Shorten URL"
                             primaryButtonOnClick={handleSave}
                             secondaryButtonText="Cancel"
                             secondaryButtonOnClick={handleClose}
                             onCloseButtonClick={handleClose}
                             title="New Short Link"
                             overflowY="none"
                             content={
                                 <>
                                     <FormField status={urlFieldStatus} statusMessage={urlStatusMessage}>
                                         <Text>Destination URL</Text>
                                         <Input size="medium"
                                                placeholder="http(s)://"
                                                onChange={event => handleInputChange(event, 'longUrl')}/>
                                     </FormField>
                                     <Box direction='horizontal' paddingTop="small" gap="tiny">
                                         <Text size="small">{instance.availableLinks} more links available this month</Text>
                                         <Tooltip inline
                                                  content={
                                                      <Text size="small" light={true}>
                                                          Your current plan includes {instance.linksLimit} links per month. {<UpgradeTooltipMessage requiredVendorProductIds={[VendorProductId.ultimate]} />}
                                                      </Text>
                                         }>
                                             <InfoCircleSmall color={"rgb(56, 153, 237)"}/>
                                         </Tooltip>
                                     </Box>
                                     <p/>
                                     <FormField status={titleFieldStatus}
                                                statusMessage={titleStatusMessage}
                                                required={false}>
                                         <Text>Title (optional)</Text>
                                         <Input size="medium"
                                                placeholder="Title"
                                                onChange={event => handleInputChange(event, 'title')}/>
                                     </FormField>
                                     <Box direction="vertical" paddingTop="medium" paddingBottom="small">
                                         <Heading size="small">Customize your link</Heading>
                                     </Box>
                                     <Box direction='horizontal'>
                                         <FormField>
                                             <Box direction='horizontal'>
                                                 <Text>Domain</Text>
                                                 { shouldDisableDomainManagement(instance) &&
                                                     <Tooltip inline
                                                              content={
                                                                  <Text size="small" light={true}>
                                                                      Branded links aren't available on your current plan. {<UpgradeTooltipMessage requiredVendorProductIds={[VendorProductId.business, VendorProductId.ultimate]} />}
                                                                  </Text>
                                                     }>
                                                         <LockLocked color={"rgb(56, 153, 237)"} />
                                                     </Tooltip> }
                                             </Box>
                                             <Dropdown options={dropdownOptions()}
                                                       selectedId={selectedDomainOptionId}
                                                       disabled={shouldDisableDomainManagement(instance)}
                                                       onSelect={(option) => handleCustomDomainChange(option)}/>
                                         </FormField>

                                         <Box paddingTop="SP4" paddingLeft="tiny" paddingRight="tiny">
                                             <h6 style={{
                                                 fontSize: '30px',
                                                 fontWeight: "normal",
                                                 margin: "0px"
                                             }}>/</h6>
                                         </Box>

                                         <FormField status={customShortUrlFieldStatus}
                                                    statusMessage={customShortUrlFieldStatusMessage}
                                                    required={false}>
                                             <Text>Custom back-half (optional)</Text>
                                             <Input size="medium"
                                                    placeholder="your-custom-back-half"
                                                    disabled={instance.availableCustomShortUrlCodes <= 0}
                                                    onChange={event => handleInputChange(event, 'customShortUrlCode')}/>
                                         </FormField>
                                     </Box>
                                     <Box direction='horizontal' paddingTop="small" gap="tiny">
                                         <Text size="small"> {instance.availableCustomShortUrlCodes} more custom back-halves available this month</Text>
                                         <Tooltip inline
                                                  content={
                                                      <Text size="small" light={true}>
                                                          Your current plan includes {instance.customShortUrlLimit} custom back-halves per month. {<UpgradeTooltipMessage requiredVendorProductIds={[VendorProductId.ultimate]} />}
                                                      </Text>
                                         }>
                                             <InfoCircleSmall color={"rgb(56, 153, 237)"}/>
                                         </Tooltip>
                                     </Box>
                                     <p/>
                                     <FormField>
                                         <Box direction='horizontal' gap="tiny">
                                             <ToggleSwitch size="medium"
                                                           checked={isQrCodeApplied}
                                                           disabled={instance.availableQrCodes <= 0}
                                                           onChange={event => handleToggleSwitchChange(event)}/>
                                                 <Text size="small">Generate QR-code, {instance.availableQrCodes} more available this month</Text>
                                                 <Tooltip inline
                                                          content={
                                                              <Text size="small" light={true}>
                                                                  Your current plan includes {instance.qrCodesLimit} QR Codes per month. {<UpgradeTooltipMessage requiredVendorProductIds={[VendorProductId.ultimate]} />}
                                                              </Text>
                                                          }>
                                                     <InfoCircleSmall color={"rgb(56, 153, 237)"}/>
                                                 </Tooltip>
                                         </Box>
                                     </FormField>
                                 </>
                             }
          />
      </Modal>
  );
};

export default CutLink;
