import {useMutation, useQueryClient, useSuspenseQuery} from "@tanstack/react-query";

import {deleteDomainById, domainVerificationRequest, getDomains, saveDomain} from "../api/domainClient";
import {DomainResponse, VerificationStatus} from "../models/models";

export const useDomains = (searchParams: URLSearchParams, verificationStatuses: Set<VerificationStatus> = new Set()) => {
    const verificationKey = Array.from(verificationStatuses).sort().join(",");

    const { data: domains } = useSuspenseQuery<DomainResponse[]>({
        queryKey: ["domains", verificationKey],
        queryFn: () => getDomains(searchParams, verificationStatuses),
        staleTime: 0,
        refetchOnMount: true,
        refetchOnWindowFocus: false
    });

    return domains;
};

export const useCreateDomain = () => {
    const queryClient = useQueryClient();

    const { mutateAsync: createDomain } = useMutation({
        mutationFn: saveDomain,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["domains"] })
        }
    });

    return createDomain;
};

export const useRestartDomainVerification = () => {
    const queryClient = useQueryClient();

    const { mutateAsync: restartDomainVerification } = useMutation({
        mutationFn: domainVerificationRequest,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["domains"] })
        }
    });

    return restartDomainVerification;
}

export const useDeleteDomain = () => {
    const queryClient = useQueryClient();

    const { mutateAsync: deleteDomain } = useMutation({
        mutationFn: deleteDomainById,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["shortLinks"] });
            queryClient.invalidateQueries({ queryKey: ["domains"] })
        }
    });

    return deleteDomain;
};
