import {AggregationPeriod, VendorProductId} from "../models/models";

export const getMinDate = (createdDate: Date, aggregationPeriod: AggregationPeriod, vendorProductId: VendorProductId) : Date => {
  const minDate = new Date();

  if (vendorProductId === VendorProductId.basic) {
      minDate.setDate(minDate.getDate() - 29);
  } else if (vendorProductId === VendorProductId.business) {
      if (aggregationPeriod === AggregationPeriod.Monthly) {
          minDate.setMonth(minDate.getMonth() - 2);
          minDate.setDate(1);
      } else {
          minDate.setDate(minDate.getDate() - 89);
      }
  } else if (vendorProductId === VendorProductId.ultimate) {
      if (aggregationPeriod === AggregationPeriod.Monthly) {
          minDate.setMonth(minDate.getMonth() - 11);
          minDate.setDate(1);
      } else {
          minDate.setDate(minDate.getDate() - 364);
      }
  }

  if (createdDate < minDate) {
      return minDate;
  }

  return createdDate;
};

export const getStartDate = (createdDate: Date, aggregationPeriod: AggregationPeriod) : Date => {
  const initStartDate: Date = new Date();

  switch (aggregationPeriod) {
      case AggregationPeriod.Daily:
          initStartDate.setDate(initStartDate.getDate() - 7);
          break;
      case AggregationPeriod.Monthly:
          initStartDate.setMonth(initStartDate.getMonth() - 2)
          initStartDate.setDate(1);
          break;
  }

  if (createdDate < initStartDate) {
      return initStartDate;
  }
  
  return createdDate;
};

export const getMonthLastDate = (date: Date) => {
    if (date.toDateString() === new Date().toDateString()) {
        return date;
    }
    return new Date(date.getFullYear(), date.getMonth() + 1, 0);
};
