import axiosClient from "./tinyLinksApiClientConfiguration";
import {InstanceInfoResponse} from "../models/models";

export const fetchInstance = async (instanceId: string): Promise<InstanceInfoResponse> => {
    const { data } = await axiosClient.get<InstanceInfoResponse>(
        "/api/v1/link-shortener/instance",
        { params:
                {
                    instance: instanceId
                }
        }
    );
    return data;
};
