import {
    MessageModalLayout,
    Modal,
    Text
} from '@wix/design-system';
import React, {useEffect, useState} from 'react';
import {useSearchParams} from "react-router-dom";
import {useDeleteShortLink} from "../../store/shortLinkStore";

interface DeleteShortLinkProps {
    shortUrl: string;
    title: string;
    onClose: () => void;
}

const DeleteShortLink: React.FC<DeleteShortLinkProps> = (
    {
        shortUrl,
        title,
        onClose
    }) => {

  const [linkName, setLinkName] = useState<string>(title);
  const [shortUrlCode, setShortUrlCode] = useState<string>("");
  const [showModal, setShowModal] = useState(true);
  const [searchParams] = useSearchParams();

  const deleteShortLinkByShortUrl = useDeleteShortLink();

  useEffect(() => {
      setShortUrlCode(shortUrl.split('/')[3]);
      setLinkName(title);
  }, [shortUrl, title]);

  const handleDelete = async () => {
      await deleteShortLinkByShortUrl({ shortUrlCode, searchParams });
      setShowModal(false);
      onClose();
  };

  const handleClose = () => {
      setShowModal(false);
      onClose();
  };

  return (
      <Modal isOpen={showModal}
             onRequestClose={handleClose}
             shouldCloseOnOverlayClick
             screen="desktop">
          <MessageModalLayout theme="destructive"
                              title="Delete this link?"
                              primaryButtonText="Delete"
                              primaryButtonOnClick={handleDelete}
                              secondaryButtonText="Cancel"
                              secondaryButtonOnClick={handleClose}
                              onCloseButtonClick={handleClose}
                              content={
                                        <Text>
                                            You’re about to delete your <b>{linkName}</b> short link.
                                            Your short link will become unpublished and can’t be edited.
                                        </Text>
                              }
          />
      </Modal>
  );
};

export default DeleteShortLink;
