import {
    CustomModalLayout,
    Modal,
    Text,
} from '@wix/design-system';
import React, {useEffect, useState} from 'react';
import {useSearchParams} from "react-router-dom";
import {useGenerateQrCode} from "../../store/qrCodeStore";

interface QrCodeConfirmationModalProps {
    linkId: number;
    title: string;
    onClose: () => void;
    onSubmit: (qrCodeId: number, linkId: number) => void;
}

const QrCodeConfirmationModal: React.FC<QrCodeConfirmationModalProps> = (
    {
        linkId,
        title,
        onClose,
        onSubmit
    }) => {

  const [shortLinkId, setShortLinkId] = useState<number>(linkId);
  const [shortLinkTitle, setShortLinkTitle] = useState<string>(title);
  const [showModal, setShowModal] = useState(true);
  const [searchParams] = useSearchParams();

  const generateQrCode = useGenerateQrCode();

  useEffect(() => {
      setShortLinkId(linkId);
      setShortLinkTitle(title);
  }, [linkId, title]);

  const handleSubmit = async (linkId: number) => {
      const { id: qrCodeId} = await generateQrCode({shortLinkId, searchParams});

      onSubmit(qrCodeId, linkId);
      handleClose();
  };

  const handleClose = () => {
      setShowModal(false);
      onClose();
  };

  return (
      <Modal isOpen={showModal}
             onRequestClose={handleClose}
             shouldCloseOnOverlayClick
             screen="desktop">
          <CustomModalLayout primaryButtonText="Sumbit"
                             primaryButtonOnClick={() => handleSubmit(linkId)}
                             secondaryButtonText="Cancel"
                             secondaryButtonOnClick={handleClose}
                             onCloseButtonClick={handleClose}
                             title="Generate new QR-code?"
                             overflowY="none"
                             content={
                                 <Text>
                                     Do you want to generate a QR-code for the <b>{shortLinkTitle}</b> link?
                                     <br/>
                                     Submit to proceed.
                                 </Text>
                             }
          />
      </Modal>
  );
};

export default QrCodeConfirmationModal;
