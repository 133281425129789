import React, {useEffect, useState} from "react";
import {Box, RadioGroup} from "@wix/design-system";
import ImageUploader from "../imageutils/ImageUploader";
import {convertImageToBase64} from "../../helpers/imageToBase64Helper";
import {getShortLinkFavicons} from "../../api/shortLinkClient";
import {useSearchParams} from "react-router-dom";

export type QrCodeLogoType = "no-logo" | "short-link-logo" | "custom";

interface QrCodeIconSelectProps {
    shortLinkId: number;
    initLogoType: QrCodeLogoType;
    initLogoBase64: string | undefined;
    initCustomLogoFileName: string | undefined;
    onLogoChange: (imageBase64: string | undefined, logoType: QrCodeLogoType, customLogoFileName: string | undefined) => void;
}

const QrCodeLogoSelector: React.FC<QrCodeIconSelectProps> = (
    {
        shortLinkId,
        initLogoType,
        initLogoBase64,
        initCustomLogoFileName,
        onLogoChange
    }) => {

  const [logoType, setLogoType] = useState<QrCodeLogoType>();
  const [shortLinkLogoBase64, setShortLinkLogoBase64] = useState<string | undefined>(undefined);
  const [customLogo, setCustomLogo] = useState<string | undefined>(undefined);
  const [customLogoFileName, setCustomLogoFileName] = useState<string | undefined>(undefined);

  const [searchParams] = useSearchParams();

  useEffect(() => {
      setLogoType(initLogoType);
      if (initLogoType === "short-link-logo") {
          setShortLinkLogoBase64(initLogoBase64);
      }

      if (initLogoType === "custom") {
          setCustomLogo(initLogoBase64);
          setCustomLogoFileName(initCustomLogoFileName);
      }

  }, [shortLinkId, initLogoType, initLogoBase64, initCustomLogoFileName]);

  const handleChange = (logoType: QrCodeLogoType) => {
      setLogoType(logoType);

      if (logoType === "no-logo") {
          onLogoChange(undefined, logoType, customLogoFileName);
      } else if (logoType === "short-link-logo") {
          if (shortLinkLogoBase64) {
              onLogoChange(shortLinkLogoBase64, logoType, customLogoFileName);
          } else {
              getShortLinkFavicons(shortLinkId, searchParams)
                  .then(res => {
                      const logoBase64 = res.data;
                      setShortLinkLogoBase64(logoBase64);
                      onLogoChange(logoBase64, logoType, customLogoFileName);
                  });
          }
      } else if (logoType === "custom") {
          if (customLogo) {
              onLogoChange(customLogo, logoType, customLogoFileName);
          }
      }
  };

  const onImageUpload = (image: File) => {
      convertImageToBase64(image)
          .then((customLogoBase64) => {
              const customLogoFileName = image.name;
              setCustomLogo(customLogoBase64);
              setCustomLogoFileName(customLogoFileName);

              onLogoChange(customLogoBase64, "custom", customLogoFileName);
          })
          .catch(() => setCustomLogo(undefined));
  };

  return (
      <Box direction="vertical" gap="small">
          <RadioGroup value={logoType} onChange={ (iconType) => handleChange(iconType as QrCodeLogoType) }>
              <RadioGroup.Radio value="no-logo">No logo</RadioGroup.Radio>
              <RadioGroup.Radio value="short-link-logo">Use short link icon</RadioGroup.Radio>
              <RadioGroup.Radio value="custom">Custom</RadioGroup.Radio>
          </RadioGroup>

          { logoType === "custom" && (
              <ImageUploader currentSelectedFile={customLogoFileName} onImageSelect={ (image) => onImageUpload(image) }/>
          )}
      </Box>
  );
}

export default QrCodeLogoSelector;
