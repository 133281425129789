import * as React from 'react';
import {CustomIconProps} from "./CustomIconProps";

const QrBorderCircle: React.FC<CustomIconProps> = ({ size = 60, ...props }) => {
  return (
      <svg xmlns="http://www.w3.org/2000/svg"
           viewBox="0 0 300 300"
           style={{ width: '100%', height: '100%', maxWidth: size, maxHeight: size }}
           {...props}
      >
          <rect width={290}
                height={290}
                x={5}
                y={5}
                fill="none"
                stroke="#000"
                strokeDasharray="0 0"
                strokeWidth={10}
                rx={195}
          />
      </svg>
  );
}

export default QrBorderCircle;
