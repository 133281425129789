import React from "react";
import {VendorProductId} from "../../models/models";
import {useSearchParams} from "react-router-dom";
import {useInstance} from "../../store/instanceStore";

interface UpgradeTooltipProps {
    content?: string,
    requiredVendorProductIds: VendorProductId[]
}

const UpgradeTooltipMessage: React.FC<UpgradeTooltipProps> = (
    {
        content="Upgrade for more.",
        requiredVendorProductIds
    }) => {

  const [searchParams] = useSearchParams();
  const instance = useInstance(searchParams);

  if (!requiredVendorProductIds.includes(instance.vendorProductId)) {
      const upgradeUrl = `https://manage.wix.com/app-pricing-plans/${instance.appId}/plan?meta-site-id=${instance.siteId}&origin=in_app_dashboard`
      return <a href={upgradeUrl} target="_blank" rel="noopener noreferrer"><u>{content}</u></a>
  }
  return null
};

export default UpgradeTooltipMessage;
