import tinyLinksApiClient from "./tinyLinksApiClientConfiguration";
import {QrCodeRequest, QrCodeResponse} from "../models/models";

export const createQrCodeCode = async ({ shortLinkId, searchParams }: { shortLinkId: number, searchParams: URLSearchParams }): Promise<QrCodeResponse> => {
    const { data } = await tinyLinksApiClient.post<QrCodeResponse>(
        "/api/v1/qr",
        {shortLinkId: shortLinkId},
        {
            params: {
                instance: searchParams.get("instance"),
            }
        });

    return data;
};

export const getQrCode = async (qrCodeId: number, queryParams: URLSearchParams) => {
    try {
        return await tinyLinksApiClient.get<QrCodeResponse>(
            `/api/v1/qr/${qrCodeId}`,
            {
                params: {
                    instance: queryParams.get("instance"),
                }
            });
    } catch (e) {
        throw e;
    }
};

export const updateQrCode = async (qrCodeId: number, qrCode: QrCodeRequest, queryParams: URLSearchParams) => {
    try {
        return await tinyLinksApiClient.patch<QrCodeResponse>(
            `/api/v1/qr/${qrCodeId}`,
            qrCode,
            {
                params: {
                    instance: queryParams.get("instance"),
                }
            });
    } catch (e) {
        throw e;
    }
};
