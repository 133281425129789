import axios from 'axios';
import {parseISO} from "date-fns/esm";

const ISODateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/;

const isIsoDateString = (value: unknown): value is string => {
    return typeof value === "string" && ISODateFormat.test(value);
};

const handleDates = (data: unknown) => {
    if (isIsoDateString(data)) return parseISO(data);
    if (data === null || data === undefined || typeof data !== "object") return data;

    for (const [key, val] of Object.entries(data)) {
        // @ts-expect-error this is a hack to make the type checker happy
        if (isIsoDateString(val)) data[key] = parseISO(val);
        else if (typeof val === "object") handleDates(val);
    }

    return data
};

const tinyLinksApiClient = axios.create({
    baseURL: window._env_.REACT_APP_TINY_LINKS_API_BASE_URL,
});

tinyLinksApiClient.interceptors.response.use(
    (response) => {
        handleDates(response.data);
        return response;
    },
    (error) => {
        if (axios.isAxiosError(error) && error.response) {
            return Promise.reject(error.response);
        }
        return Promise.reject(error);
    }
);

export default tinyLinksApiClient;
