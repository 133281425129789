import React from "react";
import {Button, Card, Text} from "@wix/design-system";

interface ReviewBannerProps {
    appId: string,
    onClick: () => void
}

const ReviewBanner: React.FC<ReviewBannerProps> = ({ appId, onClick }) => {

  const handleButtonClick = (appId: string) => {
      onClick();
      const url = `https://www.wix.com/app-market/add-review/${appId}`;
      window.open(url, '_blank');
  };

  return (
      <Card showShadow>
          <Card.Header title={<Text tagName="div" weight="bold" size="small">Your Feedback Matters!</Text>}
                       subtitle={<Text size="tiny" secondary>Your review helps us grow and improve. Let us know what you think!</Text>}
                       suffix={<Button skin="premium" size="medium" onClick={() => handleButtonClick(appId)}>Share Feedback</Button>}
          />
      </Card>
  );
}

export default ReviewBanner;
