import React, {useState} from 'react';
import {SegmentedToggle} from "@wix/design-system";
import {Discount, Number as NumberIcon} from "@wix/wix-ui-icons-common";
import 'chart.js/auto';
import 'chartjs-adapter-date-fns';

interface ViewModeSegmentedToggleProps {
    disabled?: boolean;
    onChange: (viewMode: "count" | "percentage") => void;
}

const ViewModeSegmentedToggle: React.FC<ViewModeSegmentedToggleProps> = (
    {
        disabled = false,
        onChange
    }) => {

  const [selectedViewMode, setSelectedViewMode] = useState<"count" | "percentage">("count");

  const handleViewModeChange = (viewMode: "count" | "percentage") => {
      setSelectedViewMode(viewMode as "count" | "percentage");
      onChange(viewMode);
  };

  return (
      <SegmentedToggle defaultSelected={selectedViewMode}
                       size="small"
                       onClick={(_, viewMode) => handleViewModeChange(viewMode as "count" | "percentage")}>
          <SegmentedToggle.Icon value="count"
                                tooltipText="Count"
                                style={{
                                    pointerEvents: disabled ? "none" : "auto",
                                    opacity: disabled ? 0.5 : 1
                                }}
          >
              <NumberIcon />
          </SegmentedToggle.Icon>
          <SegmentedToggle.Icon value="percentage"
                                tooltipText="Percentage"
                                style={{
                                    pointerEvents: disabled ? "none" : "auto",
                                    opacity: disabled ? 0.5 : 1
                                }}
          >
              <Discount />
          </SegmentedToggle.Icon>
      </SegmentedToggle>
    );
};

export default ViewModeSegmentedToggle;
