import React, {SyntheticEvent, useEffect, useState} from "react";
import {Box, Pagination, SegmentedToggle, Table, TableToolbar, Text, Tooltip} from "@wix/design-system";
import {TableColumn} from "@wix/design-system/dist/types/Table";

import {VendorProductId, ViewsPercentage} from "../../models/models";
import UpgradeTooltipMessage from "../tooltip/UpgradeTooltipMessage";

type DataType = "Country" | "City";

interface TableData {
    index: number;
    name: string;
    views: number;
    percentage: number;
}

interface PaginationChangeParams {
    page: number;
    event: SyntheticEvent;
}

interface TableAnalyticsProps {
    title: string;
    isAdvancedAnalyticsDisabled: boolean;
    countryData: Map<string, ViewsPercentage>;
    cityData: Map<string, ViewsPercentage>;
}

const TableAnalytics: React.FC<TableAnalyticsProps> = (
    {
        title,
        isAdvancedAnalyticsDisabled,
        countryData = new Map<string, ViewsPercentage>(),
        cityData = new Map<string, ViewsPercentage>()
    }) => {

  const TABLE_ROWS_QUANTITY: number = 5;

  const [selectedDataType, setSelectedDataType] = useState<DataType>("Country");
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [tableRecords, setTableRecords] = useState<TableData[]>([]);
  const [pageCount, setPageCount] = useState<number>(0);
  const [selectedPage, setSelectedPage] = useState<number>(1);

  const transformData = (data: Map<string, ViewsPercentage>): TableData[] =>
      Object.entries(data)
          .sort(([, a], [, b]) => b.viewCount - a.viewCount)
          .map(([key, value], index) => ({
              index: index + 1,
              name: key,
              views: value.viewCount,
              percentage: value.percentage
          }));

  useEffect(() => {
      const data = selectedDataType === "Country" ? countryData : cityData;
      const tableData = transformData(data);
      setTableData(tableData);
      setTableRecords(tableData.slice(0, TABLE_ROWS_QUANTITY));
      setPageCount(Math.ceil(tableData.length / TABLE_ROWS_QUANTITY));
    }, [selectedDataType, countryData, cityData]);

  const columns: TableColumn<TableData>[] = [
      {
          title: "#",
          width: "10%",
          render: (row: TableData) => row.index
      },
      {
          title: selectedDataType,
          width: "55%",
          render: (row: TableData) => selectedDataType === "Country" ? row.name : row.name.split("/")[3]
      },
      {
          title: title,
          width: "20%",
          align: "center",
          render: (row: TableData) => row.views
      },
      {
          title: "%",
          width: "15%",
          align: "center",
          render: (row: TableData) => row.percentage
      }
  ];

  const handleDataTypeChange = (dataType: DataType) => {
      if (!isAdvancedAnalyticsDisabled || dataType !== "City") {
          setSelectedDataType(dataType);
          changePage(1);
      }
  };

  const handleTablePageChange = ({ page, event }: PaginationChangeParams) => {
      event.preventDefault();
      changePage(page);
  };

  const changePage = (page: number) => {
      setSelectedPage(page);
      setTableRecords(tableData.slice((page - 1) * TABLE_ROWS_QUANTITY, (page - 1) * TABLE_ROWS_QUANTITY + TABLE_ROWS_QUANTITY));
  };

  return (
      <Box direction="vertical" height="100%" minHeight="290px">
          <Box direction="vertical" flex="1">
              <Table skin="standard" data={tableRecords} columns={columns}>
                  <TableToolbar>
                      <TableToolbar.Title>{title} by location</TableToolbar.Title>

                      <TableToolbar.Item>
                          <SegmentedToggle defaultSelected={selectedDataType}
                                           size="small"
                                           onClick={(_, dataType) => handleDataTypeChange(dataType as DataType)}>
                              <SegmentedToggle.Button key="Country"
                                                      value="Country"
                                                      style={{ minWidth: "100px" }}>
                                  Countries
                              </SegmentedToggle.Button>

                              <SegmentedToggle.Button key="City"
                                                      value="City"
                                                      size="small"
                                                      style={{
                                                          minWidth: "100px",
                                                          pointerEvents: isAdvancedAnalyticsDisabled ? "none" : "auto",
                                                          opacity: isAdvancedAnalyticsDisabled ? 0.5 : 1
                              }}>
                                  <Tooltip disabled={!isAdvancedAnalyticsDisabled}
                                           content={
                                               <Text size="small" light={true}>
                                                   {<UpgradeTooltipMessage content="Upgrade now "
                                                                           requiredVendorProductIds={ [VendorProductId.business, VendorProductId.ultimate] }/> }
                                                   to unlock analytics by cities.
                                               </Text>
                                           }>
                                      <Text>Cities</Text>
                                  </Tooltip>
                              </SegmentedToggle.Button>
                          </SegmentedToggle>
                      </TableToolbar.Item>
                  </TableToolbar>

                  <Table.Content/>

                  { tableData.length === 0 && (
                      <Table.EmptyState title="No data for selected period" />
                  )}
              </Table>
          </Box>

          <Box align="center" marginTop="15px" marginBottom="10px">
              { tableData.length > TABLE_ROWS_QUANTITY && (
                  <Pagination currentPage={selectedPage}
                              totalPages={pageCount}
                              onChange={handleTablePageChange} />
              )}
          </Box>
      </Box>
  );
};

export default TableAnalytics;
