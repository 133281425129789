import React, {useState} from 'react';
import {useNavigate, useSearchParams} from "react-router-dom";
import {
    Box,
    Button,
    Card,
    Page,
    Table,
    TableActionCell,
    TableToolbar,
    Text,
    TextButton,
    Tooltip, WixDesignSystemProvider
} from '@wix/design-system';
import {
    Activity,
    Add,
    Chat,
    Delete,
    Edit, Globe,
    Star,
    StatusAlertFilled,
    StatusWarningFilled,
    Visible
} from '@wix/wix-ui-icons-common';

import AddDomainModal from "../components/domain/AddDomainModal";
import CutLink from '../components/shortlink/CutLink';
import DeleteDomainModal from "../components/domain/DeleteDomainModal";
import DeleteShortLink from '../components/shortlink/DeleteShortLink';
import DomainManagementModal from "../components/domain/DomainManagementModal";
import EditShortLink from "../components/shortlink/EditShortLink";
import Fab from '../components/fab/Fab';
import ShortLinkLogo from "../components/shortlink/ShortLinkLogo";
import HoverButton from "../components/button/HoverButton";
import ShortLinkListItem from '../components/shortlink/ShortLinkListItem';
import UpgradeBanner from "../components/banner/UpgradeBanner";
import QrCodeConfirmationModal from "../components/qrcode/QrCodeConfirmationModal";
import RestartDomainVerificationModal from "../components/domain/RestartDomainVerificationModal";
import StartFreeTrialModal from "../components/banner/StartFreeTrialModal";
import CustomQrGenerator from "../components/qrcode/CustomQrGenerator";
import UpgradeTooltipMessage from "../components/tooltip/UpgradeTooltipMessage";
import ReviewBanner from "../components/banner/ReviewBanner";
import {handleCopy} from '../helpers/clipboardHelper';
import {
    shouldDisableAll,
    shouldDisableCutLink,
    shouldDisableDomainManagement,
    shouldDisableQrCodeGeneration,
    shouldShowUpgradeBanner
} from "../helpers/upgradeBannerHelper";
import {
    VendorProductId,
    VerificationStatus,
} from '../models/models';

import '@wix/design-system/styles.global.css';
import '../styles/main-styles.css';
import '../styles/fab-styles.css';
import {useInstance} from "../store/instanceStore";
import {useShortLinks} from "../store/shortLinkStore";
import QrCodeThumbnail from "../components/icon/QrCodeThumbnail";
import {useDomains} from "../store/domainStore";

const TinyLinks: React.FC = () => {

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const instance = useInstance(searchParams);
  const shortLinks = useShortLinks(searchParams);
  const domains = useDomains(searchParams);

  const showFreeTrialModal = instance.isFreeTrialAvailable;
  const showUpgradeBanner = shouldShowUpgradeBanner(instance);
  const isAllDisabled = shouldDisableAll(instance);
  const isCreateLinkDisabled = shouldDisableCutLink(instance);
  const isQrCodeGeneratingDisabled = shouldDisableQrCodeGeneration(instance);
  const isDomainManagementDisabled = shouldDisableDomainManagement(instance);

  const [showCutLinkModal, setShowCutLinkModal] = useState(false);
  const [showEditLinkModal, setShowEditLinkModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [showQrCodeModal, setShowQrCodeModal] = useState(false);
  const [showQrCodeConfirmationModal, setShowQrCodeConfirmationModal] = useState(false);

  const [qrCodeId, setQrCodeId] = useState<number | undefined>(undefined);

  const [showDomainManagementModal, setShowDomainManagementModal] = useState(false);
  const [showAddDomainModal, setShowAddDomainModal] = useState(false);
  const [showRestartDomainVerificationModal, setShowRestartDomainVerificationModal] = useState(false);
  const [showDeleteDomainModal, setShowDeleteDomainModal] = useState(false);

  const [domainId, setDomainId] = useState<number>(-1);
  const [domainName, setDomainName] = useState<string>("");

  const [showReviewBanner, setShowReviewBanner] = useState(true);

  const [linkIdForAction, setLinkIdForAction] = useState(1);
  const [longUrl, setLongUrl] = useState('');
  const [shortUrl, setShortUrl] = useState('');
  const [shortUrlCode, setShortUrlCode] = useState('');
  const [linkTitle, setLinkTitle] = useState('');

  const linksListItems = shortLinks?.map(shortLink => ({
      linkId: shortLink.id,
      favicon: (<ShortLinkLogo longUrl={shortLink.longUrl} />),
      shortLinkItem: (<ShortLinkListItem shortLink={shortLink} />),
      views: (
          <Box>
              <Tooltip inline content="Total Clicks & Scans">
                  <Visible color="rgb(56, 153, 237)" />
              </Tooltip>
              <Text weight="thin">{shortLink.clicks}</Text>
          </Box>
      ),
      longUrl: shortLink.longUrl,
      shortUrl: `https://${shortLink.domain.domainName}/${shortLink.shortUrlCode}`,
      shortUrlCode: shortLink.shortUrlCode,
      title: shortLink.title,
      isQrCodeGenerated: shortLink.qrCodeId !== null && shortLink.qrCodeId !== undefined,
      qrCodeId: shortLink.qrCodeId,
      domain: {
          id: shortLink.domain.id,
          domainName: shortLink.domain.domainName,
          verificationStatus: shortLink.domain.verificationStatus
      },
      status: (
          <Box>
              {shortLink.domain.verificationStatus === VerificationStatus.Deleted && (
                  <Tooltip inline content="Custom domain has been deleted. Link is unavailable.">
                      <StatusWarningFilled color={"#FFB700"} />
                  </Tooltip>
              )}
              {(shortLink.domain.verificationStatus === VerificationStatus.Failed ||
                  shortLink.domain.verificationStatus === VerificationStatus.InProgress) && (
                  <Tooltip inline content="Custom domain not verified. Please check your DNS settings and rerun domain verification.">
                      <StatusAlertFilled color={"#FE620F"} />
                  </Tooltip>
              )}
          </Box>
      ),
      createdDate: shortLink.createdDate
  }));

  const primaryAction = (url: string, domainVerificationStatus: VerificationStatus) => ({
      text: "Copy",
      disabled: isAllDisabled || domainVerificationStatus !== VerificationStatus.Verified,
      onClick: () => handleCopy(url)
  });

  const secondaryActions = (linkId: number,
                            shortUrl: string,
                            shortUrlCode: string,
                            longUrl: string,
                            title: string,
                            isQrCodeGenerated: boolean,
                            qrCodeId: number | undefined,
                            domain: {id: number, domainName: string, verificationStatus: VerificationStatus}) : (
                                 {
                                    text: string;
                                    icon: React.JSX.Element;
                                    visibility: string;
                                    skin: 'dark' | 'destructive' | undefined;
                                    disabled: boolean;
                                    onClick: () => void;
                                 } |
                                 {
                                     divider: boolean
                                 })[]  => {

      let qrCodeToolTip: string;
      if (domain.verificationStatus === VerificationStatus.Verified) {
          qrCodeToolTip = isQrCodeGenerated ? "QR-code generated" : `${instance?.availableQrCodes} available QR-codes`
      } else if (domain.verificationStatus === VerificationStatus.Deleted) {
          qrCodeToolTip = "domain was deleted. QR-code is unavailable."
      } else {
          qrCodeToolTip = "Custom domain not verified. QR-code is unavailable."
      }

      return [
          {
              text: qrCodeToolTip,
              icon: <QrCodeThumbnail isQrCodeGenerated={isQrCodeGenerated}/>,
              visibility: 'always',
              skin: 'dark',
              disabled: isQrCodeGeneratingDisabled || domain.verificationStatus !== VerificationStatus.Verified,
              onClick: () => {
                  if (isQrCodeGenerated) {
                      setShowQrCodeModal(true);
                      setQrCodeId(qrCodeId);
                  } else {
                      setShowQrCodeConfirmationModal(true);
                  }
                  setLinkIdForAction(linkId);
                  setShortUrl(shortUrl);
                  setLinkTitle(title);
              }
          },
          {
              text: "Analytics",
              icon: <Activity />,
              visibility: 'always',
              skin: 'dark',
              disabled: isAllDisabled,
              onClick: () => {
                  const instance = searchParams.get("instance");
                  navigate(`/analytics/${linkId}${instance ? `?instance=${instance}` : ""}`)
              }
          },
          {
              text: "Edit",
              icon: <Edit />,
              visibility: 'always',
              skin: 'dark',
              disabled: isAllDisabled || domain.verificationStatus !== VerificationStatus.Verified,
              onClick: () => {
                  setShowEditLinkModal(true);
                  setLinkIdForAction(linkId);
                  setLinkTitle(title);
                  setLongUrl(longUrl);
                  setShortUrlCode(shortUrlCode);
                  setDomainId(domain.id);
                  setDomainName(domain.domainName);
              }
          },
          {
              divider: true
          },
          {
              text: "Delete",
              icon: <Delete />,
              visibility: 'always',
              skin: 'destructive',
              disabled: isAllDisabled,
              onClick: () => {
                  setShowDeleteModal(true);
                  setLinkTitle(title);
                  setShortUrl(shortUrl);
                  setLongUrl(longUrl);
              }
          }
      ]
  };

  const NUM_OF_VISIBLE_SECONDARY_ACTION = 2;

  const columns = [
      {
          title: "",
          render: (row: any) => row.favicon,
          width: '32px',
          height: '32px',
      },
      {
          title: "Links",
          render: (row: any) => row.shortLinkItem,
          width: '25%',
          style: { padding: '15px 3px 15px 1px' }
      },
      {
          title: "Views",
          render: (row: any) => row.views,
          width: '4%',
          style: { padding: '15px 3px 15px 2px' }
      },
      {
          title: "Status",
          render: (row: any) => row.status,
          width: '30%',
          style: { padding: '14px 2px 16px 2px' }
      },
      {
          title: "Actions",
          render: (row: any) => (
              <TableActionCell primaryAction={primaryAction(row.shortUrl, row.domain.verificationStatus)}
                               secondaryActions={secondaryActions(row.linkId,
                                                                  row.shortUrl,
                                                                  row.shortUrlCode,
                                                                  row.longUrl,
                                                                  row.title,
                                                                  row.isQrCodeGenerated,
                                                                  row.qrCodeId,
                                                                  row.domain)}
                               alwaysShowSecondaryActions={true}
                               numOfVisibleSecondaryActions={NUM_OF_VISIBLE_SECONDARY_ACTION}
              />
          )
      }
  ];

  const openAddDomainModal = () => {
      setShowAddDomainModal(true);
      setShowDomainManagementModal(false);
  };

  const openDomainVerificationModal = (domainId: number, domainName: string) => {
      setDomainId(domainId);
      setDomainName(domainName);
      setShowRestartDomainVerificationModal(true);
      setShowDomainManagementModal(false);
  };

  const openDeleteDomainModal = (domainId: number, domainName: string) => {
      setDomainId(domainId);
      setDomainName(domainName);
      setShowDeleteDomainModal(true);
      setShowDomainManagementModal(false);
  };

  const handleReviewButton = () => {
      const url = `https://www.wix.com/app-market/add-review/${instance?.appId}`
      window.open(url, '_blank');
  };

  const handleSupportButton = () => {
      window.location.href = "mailto:support@tiny-links.com";
  }

  return (
      <WixDesignSystemProvider features={{ newColorsBranding: true }}>
        <Page height='100vh'>
            <Page.Header title="TinyLinks"
                         subtitle="Generate and Track Short Links & QR Codes"
                         minimized={true}
                         actionsBar={
                            <Box direction="horizontal" gap="SP2">
                                <Tooltip inline content={`${instance.availableLinks} available links`}>
                                    <Button size="medium"
                                            prefixIcon={<Add />}
                                            disabled={isCreateLinkDisabled}
                                            onClick={() => setShowCutLinkModal(true)}>
                                        Add Link
                                    </Button>
                                </Tooltip>
                            </Box>
                         }
            />
                
            <Page.Content>
                <Card>
                    <Table data={linksListItems} columns={columns}>
                        <TableToolbar>
                            <TableToolbar.Title>Short Links Collection</TableToolbar.Title>
                        </TableToolbar>

                        <Table.Content titleBarVisible={false} />

                        { (!linksListItems || linksListItems.length === 0) && (
                            <Table.EmptyState title="Add your first link"
                                              subtitle="Paste long URL and create beautiful short link">
                                <TextButton size="small"
                                            prefixIcon={<Add />}
                                            skin="standard"
                                            priority="secondary"
                                            disabled={isCreateLinkDisabled}
                                            onClick={() => setShowCutLinkModal(true)}>
                                                Add link
                                </TextButton>
                            </Table.EmptyState>
                        )}
                    </Table>
                </Card>
            </Page.Content>

            { showUpgradeBanner && <Page.FixedFooter children={ <UpgradeBanner appId={instance.appId}
                                                                               siteId={instance.siteId}
                                                                               vendorProductId={instance.vendorProductId}/> } /> }
            { (!showUpgradeBanner && showReviewBanner) && <Page.FixedFooter children={ <ReviewBanner appId={instance.appId}
                                                                                                     onClick={() => setShowReviewBanner(false)} /> }
            /> }
        </Page>

            { showCutLinkModal && <CutLink onSave={ (linkId, shortUrl, title, qrCodeId) => {
                                           if (qrCodeId) {
                                               setShowQrCodeModal(true);
                                               setQrCodeId(qrCodeId);
                                               setLinkIdForAction(linkId);
                                               setShortUrl(shortUrl);
                                               setLinkTitle(title);
                                           }
            }}
                                           onClose={() => {
                                               setShowCutLinkModal(false)
                                           }
            } /> }
            { showEditLinkModal && <EditShortLink linkId={linkIdForAction}
                                                  title={linkTitle}
                                                  longUrl={longUrl}
                                                  shortUrlCode={shortUrlCode}
                                                  domainId={domainId}
                                                  domainName={domainName}
                                                  onClose={() => setShowEditLinkModal(false)} /> }
            { showDeleteModal && <DeleteShortLink shortUrl={shortUrl}
                                                  title={linkTitle}
                                                  onClose={() => setShowDeleteModal(false)} /> }
            { showQrCodeConfirmationModal && <QrCodeConfirmationModal linkId={linkIdForAction}
                                                                      title={linkTitle}
                                                                      onClose={ () => setShowQrCodeConfirmationModal(false) }
                                                                      onSubmit={ (qrCodeId, linkId) => {
                                                                          setShowQrCodeConfirmationModal(false);
                                                                          setLinkIdForAction(linkId);
                                                                          setQrCodeId(qrCodeId);
                                                                          setShowQrCodeModal(true);
                                                                      }} /> }
            { showQrCodeModal && <CustomQrGenerator qrCodeId={qrCodeId as number}
                                                    shortLinkId={linkIdForAction}
                                                    shortUrl={shortUrl}
                                                shortLinkTitle={linkTitle}
                                                onClose={() => setShowQrCodeModal(false)} /> }
            { showDomainManagementModal && <DomainManagementModal openAddDomainModal={() => openAddDomainModal()}
                                                                  openRestartDomainVerificationModal={(domainId: number, domainName: string) => openDomainVerificationModal(domainId, domainName)}
                                                                  openDeleteModal={(domainId: number, domainName: string) => openDeleteDomainModal(domainId, domainName)}
                                                                  onClose={() => setShowDomainManagementModal(false)} /> }
            { showAddDomainModal && <AddDomainModal onSubmit={() => setShowDomainManagementModal(true)}
                                                    onClose={() => {
                                                        setShowAddDomainModal(false);
                                                        if (domains.length > 0) {
                                                            setShowDomainManagementModal(true);
                                                        }
                                                    }} /> }
            { showRestartDomainVerificationModal && <RestartDomainVerificationModal domainId={domainId}
                                                                                    onClose={() => {
                                                                                        setShowRestartDomainVerificationModal(false)
                                                                                        setShowDomainManagementModal(true);
                                                                                    }} /> }
            { showDeleteDomainModal && <DeleteDomainModal domainId={domainId}
                                                          domainName={domainName}
                                                          onClose={() => {
                                                              setShowDeleteDomainModal(false);
                                                              setShowDomainManagementModal(true);
                                                          }} /> }
            { showFreeTrialModal && <StartFreeTrialModal appId={instance.appId}
                                                         siteId={instance.siteId} /> }

            <Fab>
                <Tooltip inline
                         disabled={!isDomainManagementDisabled}
                         content={
                            <Text size="small" light={true}>
                                Your current plan doesn't include short links with custom domain. {<UpgradeTooltipMessage content={"Upgrade to unlock"} requiredVendorProductIds={[VendorProductId.ultimate]} />}
                            </Text>
                }>
                    <HoverButton title="Domain Managment"
                                 skin={isDomainManagementDisabled ? "premium" : "light"}
                                 disabled={isDomainManagementDisabled}
                                 onClick={() => {
                                     if (domains.length === 0) {
                                         setShowAddDomainModal(true);
                                     } else {
                                         setShowDomainManagementModal(true);
                                     }
                                 }}>
                        <Globe/>
                    </HoverButton>
                </Tooltip>

                <HoverButton title="Share Feedback" onClick={handleReviewButton}>
                    <Star/>
                </HoverButton>

                <HoverButton title="Support" onClick={handleSupportButton}>
                    <Chat/>
                </HoverButton>
            </Fab>
      </WixDesignSystemProvider>
  );
};

export default TinyLinks;
