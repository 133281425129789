import React, { useEffect, useState } from "react";
import { SegmentedToggle } from "@wix/design-system";
import QrCornerSquare from "../../icon/QrCornerSquare";
import QrCornerSquareDot from "../../icon/QrCornerSquareDot";
import QrCornerSquareExtraRounded from "../../icon/QrCornerSquareExtraRounded";
import QrCornerDot from "../../icon/QrCornerDot";
import QrCornerDotSquare from "../../icon/QrCornerDotSquare";
import QrCornerDotExtraRounded from "../../icon/QrCornerDotExtraRounded";

interface QrCodeCornersToggleProps {
    selected: string;
    onClick: (pattern: string) => void;
}

const QrCodeCornersToggle: React.FC<QrCodeCornersToggleProps> = (
    {
        selected,
        onClick
    }) => {

  const [corner, setCorner] = useState<string | undefined>(selected);

  useEffect(() => {
      setCorner(selected);
  }, [selected]);

  const handleChangeCorner = (corner: string) => {
      setCorner(corner);
      onClick(corner);
  };

  return (
      <SegmentedToggle size="medium"
                       selected={corner}
                       onClick={ (_, corner) => handleChangeCorner(corner) }>
          <SegmentedToggle.Icon key="square.square"
                                value="square.square"
                                size="medium"
                                tooltipProps={{disabled: true}}>
              <QrCornerSquare size="100%"/>
          </SegmentedToggle.Icon>
          <SegmentedToggle.Icon key="square.dot"
                                value="square.dot"
                                size="medium"
                                tooltipProps={{disabled: true}}>
              <QrCornerSquareDot size="100%"/>
          </SegmentedToggle.Icon>
          <SegmentedToggle.Icon key="square.extra-rounded"
                                value="square.extra-rounded"
                                size="medium"
                                tooltipProps={{disabled: true}}>
              <QrCornerSquareExtraRounded size="100%"/>
          </SegmentedToggle.Icon>
          <SegmentedToggle.Icon key="dot.dot"
                                value="dot.dot"
                                size="medium"
                                tooltipProps={{disabled: true}}>
              <QrCornerDot size="100%"/>
          </SegmentedToggle.Icon>
          <SegmentedToggle.Icon key="dot.square"
                                value="dot.square"
                                size="medium"
                                tooltipProps={{disabled: true}}>
              <QrCornerDotSquare size="100%"/>
          </SegmentedToggle.Icon>
          <SegmentedToggle.Icon key="dot.extra-rounded"
                                value="dot.extra-rounded"
                                size="medium"
                                tooltipProps={{disabled: true}}>
              <QrCornerDotExtraRounded size="100%"/>
          </SegmentedToggle.Icon>
      </SegmentedToggle>
  );
};

export default QrCodeCornersToggle;
