import {
    CustomModalLayout,
    Modal,
} from '@wix/design-system';
import React, {useState} from 'react';
import {useSearchParams} from "react-router-dom";
import DomainRestartVerificationInstructions from "./DomainRestartVerificationInstruction";
import {useRestartDomainVerification} from "../../store/domainStore";

interface RestartDomainVerificationModalProps {
    domainId: number,
    onClose: () => void
}

const RestartDomainVerificationModal: React.FC<RestartDomainVerificationModalProps> = (
    {
        domainId,
        onClose
    }) => {

  const [searchParams] = useSearchParams();
  const restartDomainVerification = useRestartDomainVerification();

  const [showModal, setShowModal] = useState(true);

  const handleRestartDomainVerification = async (domainId: number) => {
      await restartDomainVerification({ domainId, searchParams })
          .finally(handleClose);
  };

  const handleClose = () => {
      setShowModal(false);
      onClose();
  }

  return (
      <Modal isOpen={showModal}
             onRequestClose={handleClose}
             shouldCloseOnOverlayClick
             screen="desktop">
          <CustomModalLayout theme={'standard'}
                             title="Rerun verification for custom domain?"
                             primaryButtonText="Submit"
                             primaryButtonOnClick={ () => handleRestartDomainVerification(domainId) }
                             secondaryButtonText="Cancel"
                             secondaryButtonOnClick={handleClose}
                             onCloseButtonClick={handleClose}
                             height="54%"
                             width="35%"
                             content={ <DomainRestartVerificationInstructions/> }
          />
      </Modal>
  );
};

export default RestartDomainVerificationModal;
