import {useMutation, useQueryClient, useSuspenseQuery} from "@tanstack/react-query";

import {saveShortLink, deleteShortLinkByShortUrl, getShortLinkById, getShortLinks, updateLink} from "../api/shortLinkClient";
import {ShortLinkResponse} from "../models/models";

export const useShortLinkById = (id: number, searchParams: URLSearchParams) => {
    const { data: shortLink } = useSuspenseQuery<ShortLinkResponse>({
        queryKey: ["shortLink", id],
        queryFn: () => getShortLinkById(id, searchParams),
        staleTime: 0,
        refetchOnMount: true,
        refetchOnWindowFocus: false
    });

    return shortLink;
};

export const useShortLinks = (searchParams: URLSearchParams) => {
    const { data: shortLinks } = useSuspenseQuery<ShortLinkResponse[]>({
        queryKey: ["shortLinks"],
        queryFn: () => getShortLinks(searchParams),
        staleTime: Infinity,
        refetchOnWindowFocus: false
    });

    return shortLinks;
};

export const useCutLink = () => {
    const queryClient = useQueryClient();

    const { mutateAsync: cutLink } = useMutation({
        mutationFn: saveShortLink,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["instance"] });
            queryClient.invalidateQueries({ queryKey: ["shortLinks"] })
        }
    });

    return cutLink;
};

export const useDeleteShortLink = () => {
    const queryClient = useQueryClient();

    const { mutateAsync: deleteShortLink } = useMutation({
        mutationFn: deleteShortLinkByShortUrl,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["shortLinks"] });
        }
    });

    return deleteShortLink;
};

export const useUpdateShortLink = () => {
    const queryClient = useQueryClient();

    const { mutateAsync: updateShortLink } = useMutation({
        mutationFn: updateLink,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["shortLinks"] });
        }
    });

    return updateShortLink;
};
