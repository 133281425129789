import React, { useState } from 'react';
import {Box, Heading, IconButton, IconButtonSkin} from "@wix/design-system";

interface HoverButtonProps {
    title: string;
    disabled?: boolean;
    skin?: IconButtonSkin,
    onClick: () => void;
    children: React.ReactNode;
}

const HoverButton: React.FC<HoverButtonProps> = (
    {
        title,
        disabled = false,
        skin = "light",
        onClick,
        children
    }) => {

  const [isHovered, setIsHovered] = useState(false);

  return (
      <div onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
          {isHovered ? (
              <Box direction="horizontal"
                   verticalAlign="middle"
                   gap="SP2"
                   paddingLeft="SP2"
                   backgroundColor="rgb(255, 255, 255)"
                   border="1px solid"
                   borderColor="rgb(56, 153, 237)"
                   borderRadius={72}
              >
                  <Heading size="small">{title}</Heading>
                  <IconButton size="large"
                              skin={skin}
                              priority="primary"
                              disabled={disabled}
                              onClick={onClick}
                  >
                      {children}
                  </IconButton>
              </Box>
          ) : (
              <IconButton size="large"
                          skin={skin}
                          priority="primary"
                          disabled={disabled}
                          onClick={onClick}
              >
                  {children}
              </IconButton>
          )}
      </div>
  );
};

export default HoverButton;
