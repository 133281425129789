import React from 'react';
import {Box} from "@wix/design-system";

const defaultStyles: React.CSSProperties = { bottom: 24, right: 24 };

interface FabProps {
    style?: React.CSSProperties;
    children?: React.ReactNode;
}

const Fab: React.FC<FabProps> = (
    {
        style = defaultStyles,
        children,
    }) => {

  return (
      <ul className="rtf open"
          data-testid="fab"
          style={style}
      >
          <li className="rtf--mb__c">
              <Box direction="vertical" gap="small" align="right">
                  {children}
              </Box>
          </li>
      </ul>
  );
};

export default Fab;
