import {DecorationType, ExtensionOptions} from "qr-border-plugin";
import {BorderStyle} from "../components/qrcode/QrCodeBorderSelect";

export const simpleBorderOptions: ExtensionOptions = {
    round: 0.2,
    thickness: 10,
    color: "#000000",
    dasharray: "0 0",
    decorations: {
        top: {
            type: "text" as DecorationType,
            value: "",
            style: "",
        },
        bottom: {
            type: "text" as DecorationType,
            value: "",
            style: "",
        },
        left: {
            type: "text" as DecorationType,
            value: "",
            style: "",
        },
        right: {
            type: "text" as DecorationType,
            value: "",
            style: "",
        }
    },
    borderInner: {
        color: "#000000",
        thickness: 1,
        dasharray: "0 0"
    },
    borderOuter: {
        color: "#000000",
        thickness: 1,
        dasharray: "0 0"
    },
};

export const boldBorderOptions: ExtensionOptions = {
    round: 0.2,
    thickness: 25,
    color: "#000000",
    dasharray: "0 0",
    decorations: {
        top: {
            type: "text" as DecorationType,
            value: "",
            style: "",
        },
        bottom: {
            type: "text" as DecorationType,
            value: "",
            style: "",
        },
        left: {
            type: "text" as DecorationType,
            value: "",
            style: "",
        },
        right: {
            type: "text" as DecorationType,
            value: "",
            style: "",
        }
    },
    borderInner: {
        color: "#000000",
        thickness: 0,
        dasharray: "0 0"
    },
    borderOuter: {
        color: "#000000",
        thickness: 0,
        dasharray: "0 0"
    },
};

export const textBottomBorderOptions: ExtensionOptions = {
    round: 0.2,
    thickness: 25,
    color: "#000000",
    dasharray: "0 0",
    decorations: {
        top: {
            type: "text" as DecorationType,
            value: "",
            style: "",
        },
        bottom: {
            type: "text" as DecorationType,
            value: "scan me",
            style: "font: 20px sans-serif; fill: #FFFFFF;",
        },
        left: {
            type: "text" as DecorationType,
            value: "",
            style: "",
        },
        right: {
            type: "text" as DecorationType,
            value: "",
            style: "",
        }
    },
    borderInner: {
        color: "#000000",
        thickness: 0,
        dasharray: "0 0"
    },
    borderOuter: {
        color: "#000000",
        thickness: 0,
        dasharray: "0 0"
    },
};

interface QrBorderStyles {
    options: ExtensionOptions,
    isText: boolean,
}

export const borderStyles: { [key: string]: QrBorderStyles } = {
    "simple-border": { options: simpleBorderOptions, isText: false },
    "bold-border": { options: boldBorderOptions, isText: false },
    "text-border": { options: textBottomBorderOptions, isText: true },
};

export const getBorderStyle = (borderStyleExtension?: ExtensionOptions): BorderStyle => {
    if (!borderStyleExtension) return "no-border";

    const { thickness, decorations } = borderStyleExtension;
    if (thickness === 10) return "simple-border";
    if (thickness === 25 && decorations.bottom.value) return "text-border";

    return "bold-border";
};

export const getBorderColor = (borderStyleExtension?: ExtensionOptions) =>
    borderStyleExtension?.color || "#000000";

export const getBorderText = (borderStyleExtension?: ExtensionOptions) =>
    borderStyleExtension?.decorations.bottom.value || "SCAN ME";

export const getBorderTextColor = (borderStyleExtension?: ExtensionOptions) =>
    borderStyleExtension?.decorations.bottom.style ? parseColorCode(borderStyleExtension?.decorations.bottom.style) : "#FFFFFF";

const parseColorCode = (style: string): string => {
    const match = style.match(/fill:\s*#([0-9A-Fa-f]{6}|[0-9A-Fa-f]{3})/);
    return match ? `#${match[1]}` : "#FFFFFF";
}
