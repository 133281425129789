import {useSuspenseQuery} from "@tanstack/react-query";

import {fetchInstance} from "../api/instanceClient";
import {InstanceInfoResponse} from "../models/models";

export const useInstance = (searchParams: URLSearchParams): InstanceInfoResponse => {
    const instanceId = searchParams.get("instance") || "";

    const { data: instance} = useSuspenseQuery<InstanceInfoResponse>({
        queryKey: ["instance", { instanceId }],
        queryFn: () => fetchInstance(instanceId),
        staleTime: 1000 * 60 * 10, // Cache user info for 10 minutes
        refetchOnWindowFocus: false,
    });

    return instance;
};
