import React from 'react';
import 'chart.js/auto';
import 'chartjs-adapter-date-fns';
import { Doughnut } from 'react-chartjs-2';
import {
    Plugin,
    ChartOptions,
    ChartData
} from 'chart.js';
import {Box} from "@wix/design-system";

import DoughnutChartLegend from "./DoughnutChartLegend";
import { ViewsPercentage } from '../../models/models';

declare module 'chart.js' {
    interface PluginOptionsByType<TType> {
        centerText?: {
            viewMode?:  "count" | "percentage"
        };
    }
}

interface DoughnutChartProps {
    title: string,
    dataset: Map<string, ViewsPercentage>,
    viewMode:  "count" | "percentage"
}

const DoughnutChart: React.FC<DoughnutChartProps> = (
    {
        title,
        dataset = new Map<string, ViewsPercentage>(),
        viewMode = "count"
    }) => {


  const sortedEntries = Object.entries(dataset).sort(([, a], [, b]) =>
      viewMode === "count" ? b.viewCount - a.viewCount : b.percentage - a.percentage
  );

  const labels = sortedEntries.map(([key]) => key) || [];
  const data = sortedEntries.map(([, value]) => (viewMode === "count" ? value.viewCount : value.percentage) as number);
  const colors = sortedEntries.map((_, index) => `hsl(${(190 + index * 36) % 360}, 58%, 50%)`);

  // Memoize the transformed data
  const chartData: ChartData<'doughnut', number[], string> = {
      labels: labels,
      datasets: [
          {
              label: title,
              data: data.length > 0 ? data : [1],
              backgroundColor: colors.length > 0 ? colors : ["#d3d3d3"],
              borderWidth: 0,
              hoverOffset: data.length > 0 ? 10 : 0
          },
      ],
  };

  const options: ChartOptions<"doughnut"> = {
      responsive: true,
      maintainAspectRatio: true,
      cutout: '80%',
      plugins: {
          legend: {
              display: false
          },
          tooltip: {
              enabled: false,
          },
          centerText: {
              viewMode: viewMode,
          }
      },
      animation: {
          animateScale: true, // Animates scaling on hover
      },
      hover: {
          mode: "point",
          intersect: true,
      }
  };

  // Custom plugin to draw text in the center
  const centerTextPlugin: Plugin<'doughnut'> = {
      id: 'centerText',
      afterDraw: (chart, args, plugin) => {
          const { ctx, chartArea, data } = chart;

          const { width, height } = chartArea;
          const xCenter = width / 2 + chartArea.left;
          const yCenter = height / 2 + chartArea.top;

          const viewMode = plugin.viewMode;

          const emptyState = data.labels?.length === 0;
          const label = data.datasets[0].label as string;
          const total = emptyState
              ? (viewMode === "count" ? "0" : "-")
              : (viewMode === "count"
                  ? String(data.datasets[0].data.reduce((acc, num) => acc + num, 0))
                  : "100%");

          ctx.save();
          ctx.font = "bold 3vh Madefor, 'Helvetica Neue', Helvetica, Arial, meiryo, 'hiragino kaku gothic pro', sans-serif";
          ctx.fillStyle = 'black';
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';

          const activeElements = chart.getActiveElements();
          if (activeElements.length > 0 && !emptyState) {
              const activeElement = activeElements[0];
              const dataset = data.datasets[activeElement.datasetIndex];
              const displayValue = `${dataset.data[activeElement.index]}${viewMode === "percentage" ? "%" : ""}`;

              ctx.fillText(displayValue, xCenter, yCenter - 15);
          } else {
              ctx.fillText(total, xCenter, yCenter - 15);
          }

          ctx.font = "lighter 2.5vh Madefor, 'Helvetica Neue', Helvetica, Arial, meiryo, 'hiragino kaku gothic pro', sans-serif";
          ctx.fillText(label, xCenter, yCenter + 15);
          ctx.restore();
      }
  };

  const toDeviceData = () => {
      return labels.map((label, index) => ({
          type: label,
          value: data[index],
          color: colors[index]
      }));
  };

  return (
      <Box direction="horizontal" height="calc(40vh - 110px)" gap="SP10" verticalAlign="top">
          <Doughnut data={chartData} options={options} plugins={[centerTextPlugin]}/>
          <Box verticalAlign="top">
              <DoughnutChartLegend data={toDeviceData()} />
          </Box>
      </Box>
  );
};

export default DoughnutChart;
