import {Bounce, toast} from "react-toastify";

export const showNotification = (content: string) => {
    toast.info(content, {
        position: "top-center",
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
        transition: Bounce,
        closeButton: false,
    });
};