import React, {useState} from "react";
import {Button, Text, Tooltip} from "@wix/design-system";

import {ViewSource} from "../../models/models";
import LegendMarker from "../common/LegendMarker";
import {showNotification} from "../../helpers/notificationHelper";


interface ChartLegendProps {
    isQrCodeGenerated: boolean,
    onClick : (viewSource: ViewSource) => void
}

const ChartLegend: React.FC<ChartLegendProps> = (
    {
        isQrCodeGenerated,
        onClick
    }) => {

  const [isLinkClicksSelected, setLinkClicksSelected] = useState(true);
  const [isQrScansSelected, setIsQrScansSelected] = useState(true);
  const [isLinkClicksHovered, setIsLinkClicksHovered] = useState(false);
  const [isQrScansHovered, setIsQrScansHovered] = useState(false);

  const handleLegendClick = (viewSource: ViewSource) => {
      const isSelected = viewSource === ViewSource.Browser ? isLinkClicksSelected : isQrScansSelected;
      const otherSelected = viewSource === ViewSource.Browser ? isQrScansSelected : isLinkClicksSelected;
      const toggleSelected = viewSource === ViewSource.Browser ? setLinkClicksSelected : setIsQrScansSelected;

      if ((!isSelected || otherSelected) && isQrCodeGenerated) {
          toggleSelected(!isSelected);
          onClick(viewSource);
      } else {
          showNotification("At least one option should be selected");
      }
  };

  return (
      <>
          <Button skin="light"
                  priority="primary"
                  prefixIcon={ <LegendMarker size={20} color="rgb(56, 153, 237)" /> }
                  style={{
                      backgroundColor: isLinkClicksSelected ? 'rgb(230, 240, 255)' : 'rgb(255, 255, 255)',
                      border: '1px solid',
                      borderColor: 'rgb(30, 120, 220)',
                      boxShadow: isLinkClicksHovered ? '0 0 8px rgba(30, 120, 220, 0.5)' : 'none',
                      transition: 'box-shadow 0.2s ease-in-out',
                      maxWidth: "195px"
                  }}
                  onMouseEnter={() => setIsLinkClicksHovered(true)}
                  onMouseLeave={() => setIsLinkClicksHovered(false)}
                  onClick={() => handleLegendClick(ViewSource.Browser)}>
              <Text>Link Clicks</Text>
          </Button>

          <Tooltip content="Generate QR-code to enable"
                   disabled={isQrCodeGenerated}>
              <Button skin="light"
                      priority="primary"
                      prefixIcon={ <LegendMarker size={20} color="rgb(159, 209, 246)" /> }
                      disabled={!isQrCodeGenerated}
                      style={{
                          backgroundColor: isQrCodeGenerated ? (isQrScansSelected ? 'rgb(230, 240, 255)' : 'rgb(255, 255, 255)') : 'rgba(167, 172, 183, 0.5)',
                          border: '1px solid',
                          borderColor: 'rgb(30, 120, 220)',
                          boxShadow: isQrScansHovered ? '0 0 8px rgba(30, 120, 220, 0.5)' : 'none',
                          transition: 'box-shadow 0.2s ease-in-out',
                          maxWidth: "195px"
                      }}
                      onMouseEnter={() => setIsQrScansHovered(true)}
                      onMouseLeave={() => setIsQrScansHovered(false)}
                      onClick={() => handleLegendClick(ViewSource.Qr)}>
                  <Text>QR-code Scans</Text>
              </Button>
          </Tooltip>
      </>
  )
}

export default ChartLegend;
