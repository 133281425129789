import React, {useEffect, useState} from 'react';
import {Box, Text, IconButton, FileUpload} from '@wix/design-system';
import {Add} from "@wix/wix-ui-icons-common";

interface ImageUploaderProps {
    currentSelectedFile: string | undefined;
    onImageSelect: (file: File) => void;
}

const ImageUploader: React.FC<ImageUploaderProps> = (
    {
        currentSelectedFile,
        onImageSelect
    }) => {

  const MAX_IMAGE_SIZE = 5 * 1024 * 1024; // 5MB

  const [fileName, setFileName] = useState<string | undefined>();
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
      setFileName(currentSelectedFile);
  }, [currentSelectedFile]);

  const handleImageChange = (file: File) => {
      if (file) {
          // Validate file type
          if (!file.type.startsWith('image/')) {
              setError('Please select a valid image file.');
              return;
          }

          if (file.size > MAX_IMAGE_SIZE) {
              setError("Image file exceeds the maximum size of 5Mb.");
              return;
          }

          setError(null);
          onImageSelect(file);
          setFileName(file.name);
      }
  };

  return (
      <Box direction="horizontal" gap="tiny">
          <FileUpload accept=".jpeg,.jpg,.gif,.png"
                      multiple={false}
                      onChange={(files) => {
                          if (files && files[0]) {
                              handleImageChange(files[0]);
                          }
                      }}>
              {({ openFileUploadDialog }) => (
                  <IconButton skin="standard"
                              priority="secondary"
                              size="large"
                              onClick={openFileUploadDialog}>
                      <Add/>
                  </IconButton>
              )}
          </FileUpload>

          <Box direction="vertical">
              <Text skin="primary">Choose Image</Text>

              { !error && <Text skin="disabled"
                                size="small"
                                ellipsis
                                maxLines={1}
                          >
                            {fileName === undefined ? "Select image (max size 5Mb)" : fileName}
                          </Text>
              }

              { error && <Text skin="error" size="small">{error}</Text> }
          </Box>
      </Box>
  );
};

export default ImageUploader;
