import tinyLinksApiClient from "./tinyLinksApiClientConfiguration";
import {
    ViewAnalyticsRequest,
    ViewAnalyticsResponse,
} from '../models/models';

export const getViewAnalytics = async (analyticsRequest: ViewAnalyticsRequest, searchParams: URLSearchParams) => {
    try {
        const params = new URLSearchParams();
        params.append("instance", searchParams.get("instance") || "");
        params.append("linkId", analyticsRequest.linkId.toString());
        params.append("startDate", analyticsRequest.startDate);
        params.append("endDate", analyticsRequest.endDate);
        params.append("timeZone", analyticsRequest.timeZone);
        params.append("aggregationPeriod", analyticsRequest.aggregationPeriod);

        analyticsRequest.viewSources.forEach(source => params.append("sources", source));

        return await tinyLinksApiClient.get<ViewAnalyticsResponse>(
            "/api/v1/link-shortener/analytics/views",
            { params }
        );
    } catch (e) {
        throw e;
    }
}
