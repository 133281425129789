import React, {ChangeEvent, useState} from 'react';
import {Box, Button, CustomModalLayout, FormField, Input, Modal, Text} from "@wix/design-system";
import {StatusType} from "@wix/design-system/dist/types/FormField";
import {ValidationError} from "yup";
import {useSearchParams} from "react-router-dom";
import {domainNameSchema} from "../../validations/domainNameValidation";
import DomainSetupInstruction from "./DomainSetupInstruction";
import {useCreateDomain} from "../../store/domainStore";

interface AddDomainModalProps {
    onSubmit: () => void,
    onClose: () => void
}

const AddDomainModal: React.FC<AddDomainModalProps> = (
    {
        onSubmit,
        onClose
    }) => {

  const createDomain = useCreateDomain();

  const [domainFieldStatus, setDomainFieldStatus] = useState<StatusType>();
  const [domainStatusMessage, setDomainStatusMessage] = useState("");
  const [domainName, setDomainName] = useState<string>("");
  const [showModal, setShowModal] = useState(true);
  const [searchParams] = useSearchParams();

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value.trim();
      setDomainName(newValue);
  }

  const handleSubmit = async () => {
      try {
          domainNameSchema.validateSync({
              domainName: domainName
          });
      } catch (err: any) {
          // Handle validation errors
          if (err instanceof ValidationError) {
              setInputFieldError(err.path, err.message);
          }
          return;
      }

      await createDomain({ domainName, searchParams })
          .then(() => {
              onSubmit();
              handleClose();
          })
          .catch((error) => {
              if (error.data?.error === "DomainExistException") {
                  setInputFieldError('domainName', "Provided domain name already exists");
              }
          });
  };

  const setInputFieldError = (field: string | undefined, message: string) => {
      if (field === 'domainName') {
          setDomainFieldStatus("error")
          setDomainStatusMessage(message)
      }
  }

  const handleClose = () => {
      setShowModal(false);
      onClose();
  }

  return (
      <Modal isOpen={showModal}
             onRequestClose={handleClose}
             shouldCloseOnOverlayClick
             screen="desktop">
          <CustomModalLayout title="Custom domain"
                             onCloseButtonClick={handleClose}
                             width="65%"
                             content={ <DomainSetupInstruction/> }
                             sideActions={
                                 <Box direction="vertical" gap="SP2">
                                     <FormField status={domainFieldStatus} statusMessage={domainStatusMessage}>
                                         <Text weight="bold">Enter your custom domain</Text>
                                         <Input size="medium"
                                                placeholder="yourdomain.com"
                                                onChange={event => handleInputChange(event)}/>
                                     </FormField>
                                     <Box direction="horizontal" verticalAlign="middle" align="right" gap="SP2">
                                         <Button priority="secondary" onClick={handleClose}>Cancel</Button>
                                         <Button onClick={handleSubmit}>Submit</Button>
                                     </Box>
                                 </Box>
                             }
          />
      </Modal>
  );
}

export default AddDomainModal;
