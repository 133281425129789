import React from 'react';
import {Box, Text} from "@wix/design-system";
import LegendMarker from "../common/LegendMarker";

interface DoughnutData {
    type: string;
    value: number;
    color: string;
}

interface DoughnutChartLegendProps {
    data: DoughnutData[],
}

const DoughnutChartLegend: React.FC<DoughnutChartLegendProps> = ({data}) => {
    return (
        <Box maxWidth="100%">
            <table style={{ width: '100%', tableLayout: 'fixed' }}>
                <colgroup>
                    <col style={{ width: '70%' }} />
                    <col style={{ width: '30%' }} />
                </colgroup>
                <tbody>
                {data.map((item) => (
                    <tr key={item.type}>
                        <td style={{
                            paddingRight: "1em",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis"
                        }}>
                            <Box direction="horizontal" verticalAlign="middle" gap="SP1">
                                <LegendMarker size={15} color={item.color} />
                                <Text ellipsis>{item.type}</Text>
                            </Box>
                        </td>
                        <td style={{
                            textAlign: "right",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis"
                        }}>
                            <Text>{item.value}</Text>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </Box>
    );
};

export default DoughnutChartLegend;
