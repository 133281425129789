import React from 'react';
import {ChartOptions, TimeUnit} from 'chart.js';
import {Bar} from 'react-chartjs-2';
import 'chart.js/auto';
import 'chartjs-adapter-date-fns';

import {AggregationPeriod, ViewSource} from '../../models/models';
import {Box} from "@wix/design-system";

interface BarChartProps {
    views: Map<string, number>,
    qrViews: Map<string, number>,
    aggregationPeriod: AggregationPeriod;
    datasetVisibility: Map<ViewSource, boolean>
}

const BarChart: React.FC<BarChartProps> = (
    {
        views,
        qrViews,
        aggregationPeriod,
        datasetVisibility
    }) => {

  function getXScaleUnit(): TimeUnit {
      switch (aggregationPeriod) {
          case AggregationPeriod.Daily:
              return 'day' as TimeUnit
          case AggregationPeriod.Monthly:
              return 'month' as TimeUnit
      }
  }

  const data = {
      datasets: [
          {
              label: "Link Clicks",
              data: views,
              maxBarThickness: 150,
              backgroundColor: 'rgba(56, 153, 237, 0.5)',
              hidden: !datasetVisibility.get(ViewSource.Browser)
          },
          {
              label: "QR-code Scans",
              data: qrViews,
              maxBarThickness: 150,
              backgroundColor: 'rgba(159, 209, 246, 0.5)',
              hidden: !datasetVisibility.get(ViewSource.Qr)
          }
      ]
  };

  const options: ChartOptions<'bar'> = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
          legend: {
              display: false
          },
          tooltip: {
              displayColors: false,
              callbacks: {
                  title: () => {
                      return "";
                  }
              }
          }
      },
      scales: {
          x: {
              type: 'time',
              stacked: true,
              time: {
                  unit: getXScaleUnit()
              },
              grid: {
                  display: false
              }
          },
          y: {
              beginAtZero: true,
              stacked: true,
              grid: {
                  display: true
              },
              border: {
                  display: false
              },
              ticks: {
                  stepSize: 1
              }
          }
      }
  };

  return (
      <Box height="calc(40vh - 85px)">
          <Bar data={data} options={options} />
      </Box>
      );
};

export default BarChart;
