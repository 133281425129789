import React, { useState, useEffect } from 'react';
import { Card } from "@wix/design-system";

interface SlideshowProps {
    images: string[];
    interval?: number; // Interval in milliseconds
}

const Slideshow: React.FC<SlideshowProps> = (
    {
        images,
        interval = 3000
    }) => {

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isFading, setIsFading] = useState(false);

  // Automatically change images with effect
  useEffect(() => {
      const timer = setInterval(() => {
          triggerNextSlide();
      }, interval);

      return () => clearInterval(timer); // Cleanup on unmount
  }, [images.length, interval]);

  const triggerNextSlide = () => {
      setIsFading(true);
      setTimeout(() => {
          setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
          setIsFading(false);
      }, 500); // Match the fade duration
  };

  // Styles for the image container and fade effect
  const imageStyles = {
      width: '100%',
      height: '100%',
      transition: 'opacity 0.5s ease-in-out',
      opacity: isFading ? 0 : 1,
      borderRadius: '10px',
      boxShadow: "0 0 4px rgb(170, 219, 252)"
  };

  const containerStyles = {
      textAlign: 'center' as const,
      position: 'relative' as const,
  };

  return (
      <Card>
          <Card.Content>
              <div style={containerStyles}>
                  <img src={images[currentIndex]}
                       alt={`Slide ${currentIndex}`}
                       style={imageStyles}
                  />
              </div>
          </Card.Content>
      </Card>
  );
};

export default Slideshow;
