import React, {useEffect, useState} from "react";
import {ShapeType} from "qr-code-styling/lib/types";
import QrShapeSquare from "../../icon/QrShapeSquare";
import QrShapeCircle from "../../icon/QrShapeCircle";
import {Box, Thumbnail} from "@wix/design-system";

interface QrCodeShapeToggleProps {
    selected: ShapeType | undefined
    onClick: (shape: ShapeType) => void
}

const QrCodeShapeToggle: React.FC<QrCodeShapeToggleProps> = (
    {
        selected,
        onClick
    }) => {

  const [shape, setShape] = useState<ShapeType>();

  useEffect(() => {
      setShape(selected);
  }, [selected]);

  const handleChangeShape = (shape: ShapeType) => {
      setShape(shape);
      onClick(shape);
  };

  return (
      <Box direction="horizontal" gap="SP2">
          <Thumbnail size="medium"
                     selected={"square" === shape}
                     onClick={() => handleChangeShape("square")}
                     image={<QrShapeSquare size="100%"/>}
                     contentAlignment="top"
                     textPosition="outside"
                     width="15%"
                     height="90%"
          />
          <Thumbnail size="medium"
                     selected={"circle" === shape}
                     onClick={() => handleChangeShape("circle")}
                     image={<QrShapeCircle size="100%"/>}
                     contentAlignment="top"
                     textPosition="outside"
                     width="15%"
                     height="90%"
          />
      </Box>
  );
}

export default QrCodeShapeToggle;
