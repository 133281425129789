import React from 'react';
import {QR} from "@wix/wix-ui-icons-common";

interface QrCodeThumbnailProps {
    isQrCodeGenerated: boolean
}

const QrCodeThumbnail: React.FC<QrCodeThumbnailProps> = ({ isQrCodeGenerated}) => {

    const iconStyle = {
        border: '3px solid rgb(30, 120, 220))',
        boxShadow: '0 0 8px rgba(30, 120, 220, 0.7)',
        borderRadius: '50%',
        padding: '5px'
    };

    if (isQrCodeGenerated) {
        return (
            <div style={iconStyle}>
                <QR />
            </div>
        )
    } else {
        return <QR />
    }


};

export default QrCodeThumbnail;
