import tinyLinksApiClient from "./tinyLinksApiClientConfiguration";
import {
    ShortLinkDeleteRequest,
    ShortLinkUpdateRequest,
    ShortLinkRequest,
    ShortLinkResponse,
} from '../models/models';

export const getShortLinkById = async (shortLinkId: number, searchParams: URLSearchParams): Promise<ShortLinkResponse> => {
    const { data } = await tinyLinksApiClient.get<ShortLinkResponse>(
        `/api/v1/link-shortener/${shortLinkId}`,
        {
            params: {
                instance: searchParams.get("instance"),
            }
        });
    return data;
};

export const getShortLinks = async (searchParams: URLSearchParams): Promise<ShortLinkResponse[]> => {
    const { data } = await tinyLinksApiClient.get<ShortLinkResponse[]>(
        "/api/v1/link-shortener",
        {
            params: {
                instance: searchParams.get("instance"),
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            }
        });
    return data;
};

export const saveShortLink = async ({ shortLink, searchParams }: { shortLink: ShortLinkRequest; searchParams: URLSearchParams }): Promise<ShortLinkResponse> => {
    const { data } = await tinyLinksApiClient.post<ShortLinkResponse>(
        "/api/v1/link-shortener/cut",
        shortLink,
        {
            params: {
                instance: searchParams.get("instance")
            }
        });
    return data;
};

export const deleteShortLinks = async (deleteRequest: ShortLinkDeleteRequest, searchParams: URLSearchParams)=> {
    try {
        return await tinyLinksApiClient({
            method: 'DELETE',
            url: "/api/v1/link-shortener/delete",
            params: {instance: searchParams.get("instance")},
            data: deleteRequest
        });
    } catch (e) {
        throw e;
    }
};

export const deleteShortLinkByShortUrl = async ({ shortUrlCode, searchParams }: { shortUrlCode: string, searchParams: URLSearchParams }) => {
    const deleteRequest: ShortLinkDeleteRequest = {
        shortUrlCodes: [ shortUrlCode ]
    }

    return deleteShortLinks(deleteRequest, searchParams);
};

export const updateLink = async ({ updateLinkRequest, searchParams }: { updateLinkRequest: ShortLinkUpdateRequest, searchParams: URLSearchParams }) => {
    try {
        return await tinyLinksApiClient.patch<ShortLinkResponse>(
            "/api/v1/link-shortener/update",
            updateLinkRequest,
            {
                params: {
                    instance: searchParams.get("instance"),
                }
            })
    } catch (e) {
        throw e;
    }
};

export const getShortLinkFavicons = async (shortLinkId: number, searchParams: URLSearchParams) => {
    try {
        return await tinyLinksApiClient.get<string>(
            `/api/v1/link-shortener/${shortLinkId}/favicon`,
            {
                params: {
                    instance: searchParams.get("instance"),
                }
            });
    } catch (e) {
        throw e;
    }
};
