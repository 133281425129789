import React from 'react';
import {useSearchParams} from "react-router-dom";
import {ListItemAction, TextButton} from "@wix/design-system";

import {useInstance} from "../../store/instanceStore";

const UpgradeFooter: React.FC = () => {

  const [searchParams] = useSearchParams();
  const instance = useInstance(searchParams);

  const upgradeUrl = `https://manage.wix.com/app-pricing-plans/${instance.appId}/plan?meta-site-id=${instance.siteId}&origin=in_app_dashboard`

  return (
      <ListItemAction skin="premium"
                      highlighted
                      subtitle={
                        <TextButton skin="standard"
                                    underline="always"
                                    as="a"
                                    href={upgradeUrl}
                                    target="_blank"
                                    style={{ whiteSpace: "normal", wordBreak: "break-word" }}>
                            Upgrade to access complete analytics insights
                        </TextButton>
                      }
      />
  );
};

export default UpgradeFooter;
