import React, {useEffect, useState} from "react";
import {Box, ColorInput, IconElement, SegmentedToggle, Slider} from "@wix/design-system";
import {ColorDrop, CropRotate} from "@wix/wix-ui-icons-common";
import {generateGradient} from "../../helpers/gradientColorHelper";
import { MdGradient } from "react-icons/md";
import { RiGradienterLine } from "react-icons/ri";
import {Gradient} from "qr-code-styling";

interface ColorSelectProps {
    initSingleColor: string;
    initGradientColors: string[];
    initColorType: ColorType;
    initGradientRotation: number;
    onSingleColorSelect?: (color: string) => void;
    onGradientChange: (colors: Gradient) => void;
}

export type ColorType = "single-color" | "linear" | "radial";

export interface GradientColors {
    offset: number,
    color: string
}

const ColorSelect: React.FC<ColorSelectProps> = (
    {
        initSingleColor,
        initGradientColors,
        initColorType,
        initGradientRotation,
        onSingleColorSelect ,
        onGradientChange
    }) => {

  const [colorType, setColorType] = useState<ColorType>(initColorType);
  const [singleColor, setSingleColor] = useState<string>(initSingleColor);
  const [gradientColors, setGradientColors] = useState<string[]>(initGradientColors);
  const [rotation, setRotation] = useState<number>(initGradientRotation);

  useEffect(() => {
      setSingleColor(initSingleColor);
      setGradientColors(initGradientColors);
      setColorType(initColorType);
      setRotation(initGradientRotation);
      }, [initSingleColor, initGradientColors, initColorType, initGradientRotation]);

  const handleSetColorType = (colorType: ColorType) => {
      setColorType(colorType);
      if (colorType === "single-color") {
          handleSetSingleColor(singleColor);
      }

      if (colorType === "linear") {
          handleSetFirstColorLinear(gradientColors[0]);
          handleSetSecondColorLinear(gradientColors[1]);
          handleSetRotationSelect(rotation);
      }

      if (colorType === "radial") {
          handleSetFirstColorRadial(gradientColors[0]);
          handleSetSecondColorRadial(gradientColors[1]);
      }
  };

  const handleSetSingleColor = (color: string) => {
      setSingleColor(color);
      if (onSingleColorSelect !== undefined) {
          onSingleColorSelect(color);
      }
  };

  const handleSetFirstColorLinear = (color: string) => {
      setGradientColors([color, gradientColors[1]]);
      onGradientChange(
          {
              type: "linear",
              rotation: rotation,
              colorStops: generateGradient(color, gradientColors[1], 10) as GradientColors[]
          });
  };

  const handleSetSecondColorLinear = (color: string) => {
      setGradientColors([gradientColors[0], color]);
      onGradientChange(
          {
              type: "linear",
              rotation: rotation,
              colorStops: generateGradient(gradientColors[0], color, 10) as GradientColors[]
            });
  };

  const handleSetRotationSelect = (rotation: number) => {
      setRotation(rotation);
      onGradientChange(
          {
              type: "linear",
              rotation: rotation,
              colorStops: generateGradient(gradientColors[0], gradientColors[1], 10) as GradientColors[]
          });
  };

  const handleSetFirstColorRadial = (color: string) => {
      setGradientColors([color, gradientColors[1]]);
      onGradientChange(
          {
              type: "radial",
              colorStops: generateGradient(color, gradientColors[0], 10) as GradientColors[]
          });
  };

  const handleSetSecondColorRadial = (color: string) => {
      setGradientColors([gradientColors[0], color]);
      onGradientChange(
          {
              type: "radial",
              colorStops: generateGradient(gradientColors[0], color, 10) as GradientColors[]
          });
  };

  return (
      <Box direction="vertical" gap="medium">
          <SegmentedToggle size="medium" selected={colorType}
                           onClick={(_, colorType) => handleSetColorType(colorType as ColorType)}>
              <SegmentedToggle.Button value="single-color"
                                      size="medium"
                                      prefixIcon={ <ColorDrop color="rgb(56, 153, 237)" /> }>
                  Single color
              </SegmentedToggle.Button>
              <SegmentedToggle.Button value="linear"
                                      size="medium"
                                      prefixIcon={ MdGradient({ size: 22, color: "rgb(56, 153, 237)" }) as IconElement }>
                  Linear gradient
              </SegmentedToggle.Button>
              <SegmentedToggle.Button value="radial"
                                      size="medium"
                                      prefixIcon={ RiGradienterLine({ size: 22, color: "rgb(56, 153, 237)" }) as IconElement }>
                  Radial gradient
              </SegmentedToggle.Button>
          </SegmentedToggle>

          { colorType === "single-color" &&
              <Box direction="horizontal" gap="medium">
                  <ColorInput value={singleColor}
                              popoverPlacement='top-end'
                              popoverProps={{ animate: true }}
                              onConfirm={ (color) => handleSetSingleColor(color as string) } />
              </Box>
          }

          { colorType === "linear" &&
              <Box direction="vertical" gap="medium">
                  <Box direction="horizontal" gap="medium">
                      <ColorInput value={gradientColors[0]}
                                  popoverPlacement='top-end'
                                  popoverProps={{ animate: true }}
                                  onConfirm={ (color) => handleSetFirstColorLinear(color as string) }

                      />

                      <ColorInput value={gradientColors[1]}
                                  popoverPlacement='top-end'
                                  popoverProps={{ animate: true }}
                                  onConfirm={ (color) => handleSetSecondColorLinear(color as string) }
                      />
                  </Box>
                  <Box direction="horizontal" gap="medium">
                      <CropRotate color={"rgb(56, 153, 237)"} />
                      <Slider value={rotation}
                              min={0}
                              max={5}
                              step={1}
                              displayMarks={false}
                              onChange={ (rotation) => handleSetRotationSelect(rotation as number) }
                      />
                  </Box>
              </Box>
          }

          { colorType === "radial" &&
              <Box direction="horizontal" gap="tiny">
                  <ColorInput value={gradientColors[0]}
                              popoverPlacement='top-end'
                              popoverProps={{ animate: true }}
                              onConfirm={ (color) => handleSetFirstColorRadial(color as string) }
                  />

                  <ColorInput value={gradientColors[1]}
                              popoverPlacement='top-end'
                              popoverProps={{ animate: true }}
                              onConfirm={ (color) => handleSetSecondColorRadial(color as string) }
                  />
              </Box>
          }
      </Box>
  );
};

export default ColorSelect;
