import React from "react";
import {VerificationStatus} from "../../models/models";
import {Badge} from "@wix/design-system";

const DomainStatusBadge: React.FC<{children: VerificationStatus}> = ({children}) => {

  const skin = () => {
      switch (children) {
          case VerificationStatus.Verified: return "success"
          case VerificationStatus.InProgress: return "standard"
          case VerificationStatus.Failed: return "urgent"
          default: return undefined
      }
  }

  function formatString(str: string): string {
      return str.replace(/_/g, ' ');
  }

  return (
      <Badge skin={skin()} type="outlined">
          {formatString(children)}
      </Badge>
  );
}

export default DomainStatusBadge;