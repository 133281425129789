import React, {useState} from "react";
import {Box, Divider, Dropdown, DropdownLayoutValueOption, Input} from "@wix/design-system";
import {Statistics} from "@wix/wix-ui-icons-common";

import ChartLegend from "./ChartLegend";
import DateRangePickerComponent from "./DateRangePickerComponent";
import {getMinDate, getStartDate} from "../../helpers/dateRangeUtil";
import {AggregationPeriod, VendorProductId, ViewSource} from "../../models/models";
import UpgradeFooter from "./UpgradeFooter";

interface AnalyticsHeaderProps {
    aggregationPeriod: AggregationPeriod,
    isQrCodeGenerated: boolean,
    createdDate: Date,
    vendorProductId: VendorProductId,
    onLegendClick: (datasetVisibility: Map<ViewSource, boolean>) => void,
    onAggregationPeriodChange: (aggregationPeriod: AggregationPeriod, startDate: Date, endDate: Date) => void,
    onDateRangeChange: (startDate: Date, endDate: Date) => void
}

const AnalyticsHeader: React.FC<AnalyticsHeaderProps> = (
    {
        aggregationPeriod,
        isQrCodeGenerated,
        createdDate,
        vendorProductId,
        onLegendClick,
        onAggregationPeriodChange,
        onDateRangeChange
    }) => {

  const [minDate, setMinDate] = useState<Date>(getMinDate(createdDate, aggregationPeriod, vendorProductId));
  const [startDate, setStartDate] = useState<Date>(getStartDate(createdDate, aggregationPeriod));
  const [endDate, setEndDate] = useState<Date>(new Date());

  const [datasetVisibility, setDatasetVisibility] = useState<Map<ViewSource, boolean>>(
      new Map<ViewSource, boolean>([[ViewSource.Browser, true], [ViewSource.Qr, true]])
  );
  const [selectedAggregatedPeriod, setSelectedAggregatedPeriod] = useState<number | string>(0);
  const isMonthlyDisabled = vendorProductId === VendorProductId.basic || vendorProductId === VendorProductId.NOT_PURCHASED;

  const options = [
      { id: 0, value: AggregationPeriod.Daily, disabled: false },
      { id: 1, value: AggregationPeriod.Monthly, disabled: isMonthlyDisabled }
  ];

  const handleLegendClick = (viewSource: ViewSource) => {
      const newDatasetVisibility = new Map(datasetVisibility);
      newDatasetVisibility.set(viewSource, !newDatasetVisibility.get(viewSource));
      setDatasetVisibility(newDatasetVisibility);
      onLegendClick(newDatasetVisibility);
  };

  const handleAggregationPeriodChange = (option: DropdownLayoutValueOption) => {
      const newValue = option.value;
      const newAggregationPeriod = AggregationPeriod[newValue as keyof typeof AggregationPeriod];
      const newMinDate= getMinDate(createdDate, newAggregationPeriod, vendorProductId);
      const newStartDate = getStartDate(createdDate, newAggregationPeriod);
      const newEndDate = new Date();

      setSelectedAggregatedPeriod(option.id);
      setMinDate(newMinDate);
      setStartDate(newStartDate);
      setEndDate(newEndDate);

      onAggregationPeriodChange(newAggregationPeriod, newStartDate, newEndDate);
  };

  const handleDateRangeChange = (dates: Date[]) => {
      const [startDate, endDate] = dates;

      if (startDate != null && endDate != null) {
          setStartDate(startDate);
          setEndDate(endDate);
          onDateRangeChange(startDate, endDate);
      }
  };

  return (
      <Box direction="horizontal" gap="SP2" verticalAlign="middle" align="right">
          <Box direction="horizontal"
               align="left"
               verticalAlign="middle"
               gap="SP2"
               marginTop="SP1">
              <ChartLegend isQrCodeGenerated={isQrCodeGenerated}
                           onClick={(viewSource: ViewSource) => handleLegendClick(viewSource)} />
          </Box>

          <Divider direction="vertical" skin="standard" />

          <Box direction="horizontal"
               align="right"
               verticalAlign="middle"
               gap="SP2"
               marginTop="SP1">
              <Dropdown border="round"
                        options={options}
                        selectedId={selectedAggregatedPeriod}
                        prefix={
                            <Input.IconAffix>
                                <Statistics />
                            </Input.IconAffix>
                        }
                        fixedFooter={ isMonthlyDisabled ? <UpgradeFooter /> : null }
                        onSelect={(option) => handleAggregationPeriodChange(option)} />
              <DateRangePickerComponent minDate={minDate}
                                        maxDate={new Date()}
                                        startDate={startDate}
                                        endDate={endDate}
                                        aggregationPeriod={aggregationPeriod}
                                        onClose={handleDateRangeChange} />
          </Box>
      </Box>
  );
};

export default AnalyticsHeader;
