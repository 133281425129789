import {InstanceInfoResponse, VendorProductId} from "../models/models";

export const shouldShowUpgradeBanner = (instance: InstanceInfoResponse): boolean => {
    const { vendorProductId, availableLinks, isFreeTrialAvailable } = instance;

    return (
        (vendorProductId === VendorProductId.NOT_PURCHASED && !isFreeTrialAvailable) ||
        (vendorProductId === VendorProductId.basic && availableLinks / 10 <= 0.2) ||
        (vendorProductId === VendorProductId.business && availableLinks / 100 <= 0.1)
    );
};

export const shouldDisableAll = (instance: InstanceInfoResponse): boolean => {
    return instance.vendorProductId === VendorProductId.NOT_PURCHASED;
};

export const shouldDisableCutLink = (instance: InstanceInfoResponse): boolean => {
    const { vendorProductId, availableLinks } = instance;

    return vendorProductId === VendorProductId.NOT_PURCHASED || availableLinks <= 0;
};

export const shouldDisableQrCodeGeneration = (instance: InstanceInfoResponse): boolean => {
    const { vendorProductId, availableQrCodes } = instance;

    return vendorProductId === VendorProductId.NOT_PURCHASED || availableQrCodes <= 0;
};

export const shouldDisableDomainManagement = (instance: InstanceInfoResponse): boolean => {
    const { vendorProductId} = instance;

    return vendorProductId === VendorProductId.NOT_PURCHASED || vendorProductId === VendorProductId.basic;
};
