import React from "react";
import {Box, Button} from "@wix/design-system";
import {LockUnlocked} from "@wix/wix-ui-icons-common";
import {useInstance} from "../../store/instanceStore";
import {useSearchParams} from "react-router-dom";

interface BlurProps {
    disabled: boolean;
    children: React.ReactNode;
}

const Blur: React.FC<BlurProps> = ({ disabled, children }) => {

  const [searchParams] = useSearchParams();
  const { appId, siteId } = useInstance(searchParams);

  const handleButtonClick = () => {
      const url = `https://manage.wix.com/app-pricing-plans/${appId}/plan?meta-site-id=${siteId}&origin=in_app_dashboard`
      window.open(url, '_blank');
  };

  if (disabled) {
      return <>{children}</>;
  }

  return (
      <Box position="relative" display="flex" verticalAlign="middle" align="center">
          <Box filter="blur(8px)" pointerEvents="none" width="100%" height="100%">
              <Box position="absolute"
                   top="0"
                   left="0"
                   right="0"
                   bottom="0"
                   style={{
                         background: `
      linear-gradient(to left bottom, rgb(45, 177, 253), rgb(255, 255, 255, 0.5)),
      linear-gradient(to left top, rgb(255, 166, 226), rgb(255, 255, 255, 0.5))
    `,
                         opacity: 0.8
                  }}
              />
              {children}
          </Box>

          <Box position="absolute"
               top="50%"
               left="50%"
               style={{ transform: 'translate(-50%, -50%)' }}>
              <Button priority="primary"
                      skin="premium"
                      size="large"
                      prefixIcon={<LockUnlocked/>}
                      onClick={() => handleButtonClick}
              >
                  Upgrade to unlock
              </Button>
          </Box>
      </Box>
    );
}

export default Blur;
