import {BrowserRouter, Route, Routes} from "react-router-dom";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";

import TinyLinks from "./pages/TinyLinks";
import Analytics from "./pages/Analytics";
import {Suspense} from "react";
import {Box, Loader, WixDesignSystemProvider} from "@wix/design-system";

const queryClient = new QueryClient();

function App() {
  return (
      <QueryClientProvider client={queryClient}>
          <WixDesignSystemProvider>
              <Suspense fallback={
                  <Box height="100vh" display="flex" verticalAlign="middle" align="center" justifyItems="center">
                      <Loader color="blue" size="medium" statusMessage="TinyLink Loading..."/>
                  </Box>
              }>
                  <BrowserRouter>
                      <Routes>
                          <Route path="/"
                                 element={<TinyLinks />}
                          />
                          <Route path="/analytics/:id"
                                 element={<Analytics />}
                          />
                      </Routes>
                  </BrowserRouter>
              </Suspense>
          </WixDesignSystemProvider>
      </QueryClientProvider>
  );
}

export default App;
