import tinyLinksApiClient from "./tinyLinksApiClientConfiguration";
import {DomainResponse, VerificationStatus} from "../models/models";

export const getDomains = async (searchParams: URLSearchParams, verificationStatuses: Set<VerificationStatus> = new Set()): Promise<DomainResponse[]> => {
    verificationStatuses.forEach((verificationStatus) => {
        searchParams.append('verificationStatus', verificationStatus);
    });

    const { data } = await tinyLinksApiClient.get<DomainResponse[]>(
        "/api/v1/link-shortener/domain",
        { params: searchParams }
    );

    return data;
};

export const saveDomain = async ({ domainName, searchParams }: { domainName: string, searchParams: URLSearchParams }): Promise<DomainResponse> => {
    const { data } = await tinyLinksApiClient.post<DomainResponse>(
        `/api/v1/link-shortener/domain?domainName=${domainName}`,
        {},
        { params: { instance: searchParams.get("instance") } }
    );

    return data;
};

export const domainVerificationRequest = async ({ domainId, searchParams }: { domainId: number, searchParams: URLSearchParams }): Promise<DomainResponse> => {
    const { data } = await tinyLinksApiClient.patch<DomainResponse>(
        `/api/v1/link-shortener/domain/${domainId}`,
        {},
        { params: { instance: searchParams.get("instance") } }
    );

    return data;
}

export const deleteDomainById = async ({ domainId, searchParams }: { domainId: number, searchParams: URLSearchParams }) => {
    try {
        return await tinyLinksApiClient.delete(
            `/api/v1/link-shortener/domain/${domainId}`,
            { params: searchParams }
        )
    } catch (e) {
        throw e;
    }
};
