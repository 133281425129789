import React from "react";

interface LegendMarkerProps {
    size: number,
    color: string,
}

const LegendMarker: React.FC<LegendMarkerProps> = (
    {
        size,
        color
    }) => {

  return (
      <div style={{
          width: size,
          height: size,
          backgroundColor: color,
          marginRight: '4px',
          borderRadius: "50%",
          flexShrink: 0
      }}/>
  );
};

export default LegendMarker;
